import React, {useEffect} from 'react';
import {setPopup} from './store'
import {useDispatch, useSelector} from "react-redux";
import {setCounter, setSmsSend} from "../../redux/auth";
let tid = null

const Counter = () => {
  const dispatch = useDispatch()
  const counter = useSelector(state => state.auth.counter)
  const sendSms = useSelector(state => state.auth.smsSend)

  useEffect(() => {
    return () => dispatch(setSmsSend(false))
  }, [])

  useEffect(() => {
    if (sendSms) tid = setTimeout(() => dispatch(setCounter(counter - 1)), 1000)
    return () => clearTimeout(tid)
  }, [sendSms, counter])

  const getTime = () => {
    var sec_num = parseInt(counter, 10);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    // if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return minutes+':'+seconds;
  }

  const sendAgain = () => {
    dispatch(setSmsSend(false))
    dispatch(setPopup('lk-login'))
  }


  if (counter < 1) return <div className="popup-edit-dsc2" onClick={sendAgain}><span>Отправить повторно</span></div>

  return <div className="popup-edit-dsc2">Получить новый код можно будет через <b>{getTime(counter)}</b></div>
};

export default Counter;