import { useState } from 'react';
import Counter from './counter'
import {signIn} from "../../redux/auth";
import {useDispatch, useSelector} from "react-redux";

const PopupSms = ({close}) => {
  const dispatch = useDispatch()
  const phone = useSelector(state => state.auth.phone)
  const mobile = useSelector(state => state.mobile)
  const [sms, setSms] = useState('');

  const check = () => {
    if (!sms || sms.indexOf('_') !== -1) return false;
    return true;
  }

  const apply = () => {
    if(check()){
      window.dataLayer.push({
        'event': 'registration_form-send', 
        'eventCategory': 'form', 
        'eventAction': 'registration_entrance'  
      })
      const formData = new FormData()
      formData.append('grant_type', 'phone')
      formData.append('phone', phone)
      formData.append('code', sms)
      dispatch(signIn(formData))
    }
  }

  return(
    <>
      <div className="popup-title">Введите код из СМС</div>
      <div className="input"><input placeholder="Код из СМС"
                                    type="text"
                                    name="token"
                                    value={sms}
                                    onChange={e => setSms(e.target.value)}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    autoComplete="one-time-code" /></div>
      <div className={!check() ? 'lk-button disabled' : 'lk-button'} onClick={apply}>отправить</div>
      <Counter callback={() => {}} />
    </>
  )
}

export default PopupSms;