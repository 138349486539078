import { useDispatch } from 'react-redux';
import { setPopup  } from './store';

const PopupNotStarted = ({close}) => {
  const dispatch = useDispatch();
  return(
    <>
      <div className="popup-title">Розыгрыш стартует 13&nbsp;сентября в&nbsp;21-00</div>
      <div className="lk-button" onClick={() => dispatch(setPopup(null))}><span>Закрыть</span></div>
    </>
  )
}

export default PopupNotStarted;