export const IS_MOBILE = 'IS_MOBILE'

export default (state = window.innerWidth < 1000, action) => {
  const { type, response } = action

  if(type === IS_MOBILE){
    return response
  }

  return state
}


export const isMobile = response => dispatch => {
  dispatch({ type: IS_MOBILE, response })
}
