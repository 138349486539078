import React, { useEffect, useState } from "react";
import top from "../../../assets/images/top.png?2";
import top_mob from "../../../assets/images/top_mob.png?2";
import down from "../../../assets/images/down.png?2";
import down_mob from "../../../assets/images/down_mob.png?2";
import {useDispatch, useSelector} from "react-redux";
import {setPopup} from "../../../components/popups/store";
import "./questions.css";

export default function Questions({ question, answers }) {
  const dispatch = useDispatch()
  const [openContext, setOpenContext] = useState(false);
  const mobile = useSelector(state => state.mobile)

  useEffect(() => {
    const lnk1 = document.querySelector('.schedule-lnk1')
    const lnk2 = document.querySelector('.schedule-lnk2')
    if (lnk1) {
      lnk1.addEventListener('click', function(e) {
        e.preventDefault();
        dispatch(setPopup('promo-schedule1'));
        return false;
      });
    }
    if (lnk2) {
      lnk2.addEventListener('click', function(e) {
        e.preventDefault();
        dispatch(setPopup('promo-schedule2'));
        return false;
      });
    }
  })

  return (
    <div >
      <div className="questions_Item">
        <div className='questions_Item_Toggle' onClick={()=>setOpenContext(!openContext)}>
          <div className="questions_Item_Title" dangerouslySetInnerHTML={{__html: question}} />
          <div>
            {!mobile && openContext ? <img className="for-desc" src={top} alt="top" width={22} height={14} /> : <img className="for-desc" src={down} alt="down" width={22} height={14} />}
            {mobile && openContext ? <img className="for-mob" src={top_mob} alt="top" width={16} height={11} /> : <img className="for-mob" src={down_mob} alt="down" width={16} height={11} />}
          </div>
        </div>
        {openContext && <div className="questions_Item_Answer_Context" dangerouslySetInnerHTML={{__html: answers}} />}
      </div>
    </div>
  );
}
