export const SET_POPUP = 'SET_POPUP'

export default (state = null, action) => {
  const { type, name } = action

  if(type === SET_POPUP){
    return name
  }

  return state
}


export const setPopup = name => dispatch => {
  dispatch({ type: SET_POPUP, name })
}
