import React from 'react';
import {ReactComponent as Youtube} from "../../assets/icon/youtube.svg";
import {ReactComponent as Fb} from "../../assets/icon/fb.svg";
import {ReactComponent as Vk} from "../../assets/icon/vk.svg";
import {ReactComponent as Inst} from "../../assets/icon/inst.svg";
import {ReactComponent as Tw} from "../../assets/icon/tw.svg";

const Soc = () => {
  return (
    <div className="footer-soc">
      <a href="https://www.youtube.com/channel/UCM2tYLK98SqA5tt6yGyNdPw" target="_blank" rel="noopener noreferrer"><Youtube /></a>
      <a href="http://facebook.com/afimall/" target="_blank" rel="noopener noreferrer"><Fb /></a>
      <a href="http://vk.com/afimall_city" target="_blank" rel="noopener noreferrer"><Vk /></a>
      <a href="http://instagram.com/afimall/" target="_blank" rel="noopener noreferrer"><Inst /></a>
      <a href="http://twitter.com/AfimallCity" target="_blank" rel="noopener noreferrer"><Tw /></a>
    </div>
  );
};

export default Soc;