import { combineReducers } from 'redux';
import popups from '../components/popups/store';
import mobile from './mobile';
import storeMap from './store-map';
import auth from './auth'


export default combineReducers({
  mobile,
  popups,
  storeMap,
  auth
})