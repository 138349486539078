import React from 'react';
import {SvgSearchIcon} from "./icons";
import {useDispatch} from "react-redux";
import {setFilter} from "../../redux/store-map";

const Search = () => {
  const dispatch = useDispatch()
  const keyup = (ev) => dispatch(setFilter('search', ev.target.value))

  return (
    <div className="store-input">
      <input type="text" onKeyUp={keyup}/>
      <SvgSearchIcon />
    </div>
  );
};

export default Search;