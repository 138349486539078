import { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { setPopup } from "./store";
import SimpleBar from "simplebar-react";
import {userSelector} from "../../redux/auth";
import {Link} from "react-router-dom";

const PopupShedule1 = ({close}) => {
  const dispatch = useDispatch()
  const mobile = useSelector(state => state.mobile)
  const user = useSelector(userSelector)

  const getWeek = () => {
    const t = (new Date()).getTime();
    const dates = [
      [(new Date(2021, 8, 13, 20, 0, 0)).getTime(), (new Date(2021, 8, 13, 21, 0, 0)).getTime()],
      [(new Date(2021, 8, 20, 20, 0, 0)).getTime(), (new Date(2021, 8, 20, 21, 0, 0)).getTime()],
      [(new Date(2021, 8, 27, 20, 0, 0)).getTime(), (new Date(2021, 8, 27, 21, 0, 0)).getTime()],
      [(new Date(2021, 9, 4, 20, 0, 0)).getTime(), (new Date(2021, 9, 4, 21, 0, 0)).getTime()],
      [(new Date(2021, 9, 11, 20, 0, 0)).getTime(), (new Date(2021, 9, 11, 21, 0, 0)).getTime()],
      [(new Date(2021, 9, 18, 20, 0, 0)).getTime(), (new Date(2021, 9, 18, 21, 0, 0)).getTime()],
      [(new Date(2021, 9, 25, 20, 0, 0)).getTime(), (new Date(2021, 9, 25, 21, 0, 0)).getTime()],
      [(new Date(2021, 10, 1, 20, 0, 0)).getTime(), (new Date(2021, 10, 1, 21, 0, 0)).getTime()],
      [(new Date(2021, 10, 8, 20, 0, 0)).getTime(), (new Date(2021, 10, 8, 21, 0, 0)).getTime()],
      [(new Date(2021, 10, 15, 20, 0, 0)).getTime(), (new Date(2021, 10, 15, 21, 0, 0)).getTime()],
    ];
    let w = -1
    let p = -1
    for (let i = 0; i < dates.length; i++) {
      if (t >= dates[i][0] && t <= dates[i][1]) {
        w = i + 1
      } else if (t > dates[i][1]) {
        p = i + 1
      }
    }
    setWeek(w)
    setPast(p)
  }

  useEffect(() => {
    const _resize = () => {
      mobile ? setHeight(window.innerHeight - 280) : setHeight(window.innerHeight - 400);
      setIsFit(window.innerHeight >= 931);
    }
    getWeek();
    _resize();
    window.addEventListener('resize', _resize);
    return () => window.removeEventListener('resize', _resize);
  }, []);

  const [height, setHeight] = useState(window.innerHeight);
  const [isFit, setIsFit] = useState(window.innerHeight >= 931);
  const [week, setWeek] = useState(-1);
  const [past, setPast] = useState(-1);

  return(
    <>
      <div className="popup-title">Расписание розыгрыша Суперпризов</div>
      <SimpleBar style={{ maxHeight: height }} className={'popup-schedule-table' + (isFit ? ' fitted' : '')}>
        <table>
          <thead>
            <tr>
              <th>Копим баллы</th>
              <th>&nbsp;</th>
              <th>Разыгрываем купоны</th>
              <th>Статус</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>06.09-12.09.2021&nbsp;г</td>
              <td>1 неделя</td>
              <td>13.09.2021&nbsp;г в&nbsp;20:00</td>
              <td><span className={'popup-schedule-status schedule-status' + (week === 1 ? '2' : past >= 1 ? '3' : '1')}>{(week === 1 ? 'Активен' : past >= 1 ? 'Завершен' : 'Не активен')}</span></td>
            </tr>
            <tr>
              <td>13.09-19.09.2021&nbsp;г</td>
              <td>2 неделя</td>
              <td>20.09.2021&nbsp;г в&nbsp;20:00</td>
              <td><span className={'popup-schedule-status schedule-status' + (week === 2 ? '2' : past >= 2 ? '3' : '1')}>{(week === 2 ? 'Активен' : past >= 2 ? 'Завершен' : 'Не активен')}</span></td>
            </tr>
            <tr>
              <td>20.09-26.09.2021&nbsp;г</td>
              <td>3 неделя</td>
              <td>27.09.2021&nbsp;г в&nbsp;20:00</td>
              <td><span className={'popup-schedule-status schedule-status' + (week === 3 ? '2' : past >= 3 ? '3' : '1')}>{(week === 3 ? 'Активен' : past >= 3 ? 'Завершен' : 'Не активен')}</span></td>
            </tr>
            <tr>
              <td>27.09-03.10.2021&nbsp;г</td>
              <td>4 неделя</td>
              <td>04.10.2021&nbsp;г в&nbsp;20:00</td>
              <td><span className={'popup-schedule-status schedule-status' + (week === 4 ? '2' : past >= 4 ? '3' : '1')}>{(week === 4 ? 'Активен' : past >= 4 ? 'Завершен' : 'Не активен')}</span></td>
            </tr>
            <tr>
              <td>04.10-10.10.2021&nbsp;г</td>
              <td>5 неделя</td>
              <td>11.10.2021&nbsp;г в&nbsp;20:00</td>
              <td><span className={'popup-schedule-status schedule-status' + (week === 5 ? '2' : past >= 5 ? '3' : '1')}>{(week === 5 ? 'Активен' : past >= 5 ? 'Завершен' : 'Не активен')}</span></td>
            </tr>
            <tr>
              <td>11.10-17.10.2021&nbsp;г</td>
              <td>6 неделя</td>
              <td>18.10.2021&nbsp;г в&nbsp;20:00</td>
              <td><span className={'popup-schedule-status schedule-status' + (week === 6 ? '2' : past >= 6 ? '3' : '1')}>{(week === 6 ? 'Активен' : past >= 6 ? 'Завершен' : 'Не активен')}</span></td>
            </tr>
            <tr>
              <td>18.10-24.10.2021&nbsp;г</td>
              <td>7 неделя</td>
              <td>25.10.2021&nbsp;г в&nbsp;20:00</td>
              <td><span className={'popup-schedule-status schedule-status' + (week === 7 ? '2' : past >= 7 ? '3' : '1')}>{(week === 7 ? 'Активен' : past >= 7 ? 'Завершен' : 'Не активен')}</span></td>
            </tr>
            <tr>
              <td>25.10-31.10.2021&nbsp;г</td>
              <td>8 неделя</td>
              <td>01.11.2021&nbsp;г в&nbsp;20:00</td>
              <td><span className={'popup-schedule-status schedule-status' + (week === 8 ? '2' : past >= 8 ? '3' : '1')}>{(week === 8 ? 'Активен' : past >= 8 ? 'Завершен' : 'Не активен')}</span></td>
            </tr>
            <tr>
              <td>01.11-07.11.2021&nbsp;г</td>
              <td>9 неделя</td>
              <td>08.11.2021&nbsp;г в&nbsp;20:00</td>
              <td><span className={'popup-schedule-status schedule-status' + (week === 9 ? '2' : past >= 9 ? '3' : '1')}>{(week === 9 ? 'Активен' : past >= 9 ? 'Завершен' : 'Не активен')}</span></td>
            </tr>
            <tr>
              <td>08.11-14.11.2021&nbsp;г</td>
              <td>10 неделя</td>
              <td>15.11.2021&nbsp;г в&nbsp;20:00</td>
              <td><span className={'popup-schedule-status schedule-status' + (week === 10 ? '2' : past >= 10 ? '3' : '1')}>{(week === 10 ? 'Активен' : past >= 10 ? 'Завершен' : 'Не активен')}</span></td>
            </tr>
          </tbody>
        </table>
      </SimpleBar>
      {user
        ? <Link className="page-btn" to={{pathname:'/', state: {hash:'Eligibility'}}} onClick={close}><span>Участвовать</span></Link>
        : <div className="page-btn" onClick={() => dispatch(setPopup('lk-login'))}><span>Зарегистрироваться</span></div>
      }
    </>
  )
}

export default PopupShedule1;