import { useState } from 'react';
import InputMask from 'react-input-mask';
import {sendSms} from "../../redux/auth";
import {setPopup} from "./store";
import {useDispatch} from "react-redux";

const PopupEdit = ({close}) => {
  const dispatch = useDispatch()
  const [phone, setPhone] = useState('');
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);

  const check = () => {
    if(!phone || phone.indexOf('_') !== -1){
      return false;
    }

    return true;
  }


  const apply = () => {
    if(check()){
      const formData = new FormData()
      const p = phone
        .replace(/\s/g, '')
        .replace(/-/g, '')
        .replace('(', '')
        .replace(')', '')
        .slice(-10)
      formData.append('grant_type', 'phone')
      formData.append('phone', p)
      dispatch(sendSms(formData, p))
    }
  }

  return(
    <>
      <div className="popup-title">Вход / Регистрация</div>
      <div className="input">
        <InputMask placeholder="Телефон" mask="+7 (999) 999-99-99" onChange={e => setPhone(e.target.value)} value={phone} />
      </div>
      <div className="popup-edit-dsc">
        Номера телефонов регистрации на сайте <br/>
        и в мобильном приложении должны совпадать!
      </div>
      <div className={!check() ? 'lk-button disabled' : 'lk-button'} onClick={apply}><span>Получить код</span></div>
    </>
  )
}

export default PopupEdit;