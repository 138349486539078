import { ReactComponent as Logo } from "../../assets/icon/logo.svg";
import './style.css';
import Stores from "./stores";
import Soc from "./soc";
import Copy from "./copy";
import Links from "./links";
import {Link} from "react-router-dom";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPopup } from "../popups/store";
import {winnersSelector} from "../../redux/auth";
import {getGifts} from "../../pages/Main/Winners/utils";

const Footer = ({hompage}) => {
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()
  const winners = useSelector(winnersSelector)
  const gifts = getGifts(winners)
  const handleClick = () => {
    window.dataLayer.push({
      'event': 'registration_click', 
      'eventCategory': 'click', 
      'eventAction': 'registration_entrance'  
    })
    dispatch(setPopup('lk-login'))
  }
  return(
    <div className= {!hompage? "footer":"footer hompage_Footer"}>
      <div className="footer-in">
        <div className="footer-top">
          <div className="footer-logo"><Logo /></div>
          {!user && <div className="page-btn" onClick={() => handleClick()}><span>Зарегистрироваться</span></div>}
          <div className="footer__nav">
            <Link className="footer__nav__item" to={{pathname:'/', state: {hash:'AboutPromotions'}}}><span>Об акции</span></Link>
            <Link className="footer__nav__item" to={{pathname:'/', state: {hash:'Eligibility'}}}><span>Правила</span></Link>
            <Link className="footer__nav__item" to={{pathname:'/', state: {hash:'PrizeFund'}}}><span>Призовой фонд</span></Link>
            {gifts.length > 0 && <Link className="footer__nav__item" to={{pathname:'/', state: {hash:'Winners'}}}><span>Победители</span></Link>}
          </div>
          <Stores />
          <Soc />
        </div>
        <div className="footer-bottom">
          <Copy />
          <Links />
        </div>
      </div>
    </div>
  )
}

export default Footer;