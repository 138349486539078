import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Carousel from 'react-elastic-carousel'
import './style.css';
import Navigation from "../../components/Navgation/Navigation";
import Footer from '../../components/footer';
import {
  checkCanRotate,
  canRotateSelector,
  setCanRotate,
  giftSelector,
  tryRotate,
  wheelIdSelector,
  isDoublingSelector
} from "../../redux/auth";
import {setPopup} from "../../components/popups/store";
import mp3 from './assets/wheel.mp3'
let tid = null
const Wheel = () => {
  const dispatch = useDispatch()
  const mobile = useSelector(state => state.mobile)
  const canRotate = useSelector(canRotateSelector)
  const gift = useSelector(giftSelector)
  const wheelId = useSelector(wheelIdSelector)
  const isDoubling = useSelector(isDoublingSelector)

  const [active, setActive] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    dispatch(setCanRotate(false))
  }, [])

  useEffect(() => {
    if (canRotate && trigger) {
      setRotate(true)
      setTrigger(false)
      const formData = new FormData()
      formData.append('wheelId', wheelId)
      dispatch(tryRotate(formData))
    }
  }, [canRotate])

  useEffect(() => {
    const audio = document.querySelector('audio')
    if (gift) {
      audio.play();
      tid = setTimeout(() => {
        audio.pause();
        if(!active) setActive(true)
      }, 3800);
    }
    return () => {clearTimeout(tid)}
  }, [gift])


  return(
    <div className="wheel">
      <audio src={mp3}></audio>
      <Navigation />

      {!mobile && (
        <>
          <img src="/img/lk/lk3.png?2" className="lk_el3" />
          <img src="/img/lk/lk4.png?2" className="lk_el4" />
          <div className="lk_el8" />
          <div className="lk_el9" />
          <img src="/img/lk/lk1.png?2" className="lk_el10" />
          <img src="/img/lk/lk2.png?2" className="lk_el11" />
          <img src="/img/lk/lk5.png?2" className="lk_el5" />
        </>
      )}

      <img src="/img/lk/lk1.png?2" className="lk_el1" />
      <img src="/img/lk/lk2.png?2" className="lk_el2" />

      <div className="wheel-in">
        <div className="wheel-title">Колесо фортуны</div>

        {mobile && (
          <Carousel className="wheel-head" itemsToShow={1} showArrows={false}>
            <div className="wheel-head-item-wrap-mobile">
              <div className="wheel-head-item">
                <div>1</div>
                <img className="wheel-head-item-img1" src="/img/wheel/img1.png?2" />
              </div>
              Чем больше купонов накоплено
              за неделю,
              тем дороже призы
              в розыгрыше
            </div>
            <div className="wheel-head-item-wrap-mobile">
              <div className="wheel-head-item">
                <div>2</div>
                <img className="wheel-head-item-img2" src="/img/wheel/img2.png?2" />
              </div>
              Выиграть можно только
              1 приз в неделю
            </div>
            <div className="wheel-head-item-wrap-mobile">
              <div className="wheel-head-item">
                <div>3</div>
                <img className="wheel-head-item-img3" src="/img/wheel/img3.png?2" />
              </div>
              Победители супер-розыгрыша не участвуют в розыгрыше дополнительных призов «Колесо фортуны»
            </div>
          </Carousel>
        )}

        {!mobile && (
          <div className="wheel-head">
            <div>
              <div className="wheel-head-item">
                <div>1</div>
                <img className="wheel-head-item-img1" src="/img/wheel/img1.png?2" />
              </div>
              Чем больше купонов накоплено
              за неделю,
              тем дороже призы
              в розыгрыше
            </div>
            <div>
              <div className="wheel-head-item">
                <div>2</div>
                <img className="wheel-head-item-img2" src="/img/wheel/img2.png?2" />
              </div>
              Выиграть можно только
              1 приз в неделю
            </div>
            <div>
              <div className="wheel-head-item">
                <div>3</div>
                <img className="wheel-head-item-img3" src="/img/wheel/img3.png?2" />
              </div>
              Победители супер-розыгрыша не участвуют в розыгрыше дополнительных призов «Колесо фортуны»
            </div>
          </div>
        )}

        <div className="wheel-title2">
        Здесь выигрывает каждый <br />
        участник розыгрыша!
        </div>

        <div className={active ? 'wheel-box active' : 'wheel-box'}>
          <img src="/img/wheel/wheel_arrow.png?2" className="wheel-box-arrow" />
          <img src="/img/wheel/wheel_new.png?2" className={rotate ? 'wheel-box-el wheel-box-visible-element active' : 'wheel-box-el wheel-box-visible-element'} />
          <img src="/img/wheel/wheel_empty.png?2" className="wheel-box-el-empty wheel-box-hide-element" />
          {gift && isDoubling  && <div className="wheel-box-category-wrap wheel-box-hide-element">
            <div className="wheel-box-category-text">Поздравляем!<br />Удвоение баллов!</div>
          </div>}
          {gift && !isDoubling && <div className="wheel-box-category-wrap wheel-box-hide-element">
            {/*{gift.image && <img src={gift.image} className="wheel-box-category-img" />}*/}
            <div className="wheel-box-category-text">Поздравляем!<br />Вы выиграли: <span dangerouslySetInnerHTML={{__html: gift.title}} /></div>
            <div className="wheel-box-category-lnk"><a onClick={() => dispatch(setPopup('lk-prize'))}>Где получить приз?</a></div>
          </div>}

        </div>

        {!active && (
          <div className="wheel-button-wrap wheel-box-visible-element" onClick={() => { dispatch(checkCanRotate()); setTrigger(true) }}>
            <div className="lk-button">запустить колесо</div>
          </div>
        )}

        {!active && (
          <div className="wheel-dsc">
            <div>
              1.Нажмите кнопку запуска<br />
              2.Дождитесь когда Колесо определит ваш приз<br />
              3.Получите свой приз в Афимолле
            </div>
            <div>
              <strong>Внимание:</strong> вы можете выиграть только 1 приз в неделю.<br />
              Колесо фортуны активно через 30 мин после супер-розыгрыша.<br />
              <a href="/docs/Afimall_rules.pdf?3" target="_blank">Полные правила розыгрыша</a>
            </div>
          </div>
        )}
        {active && (gift || isDoubling) && (
          <div className="wheel-label">
            <div className="wheel-label-title">Следующий розыгрыш “Колесо Фортуны” <br />начнется в понедельник в 21:00</div>
            <strong>Внимание:</strong> вы можете выиграть только 1 приз в неделю.<br />
            Колесо фортуны активно через 30 мин после супер-розыгрыша.<br />
            <a href="/docs/Afimall_rules.pdf?3" target="_blank">Полные правила розыгрыша</a>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default Wheel;