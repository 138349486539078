import React, {useEffect, useState} from 'react';
import {useLocation, Link} from 'react-router-dom'
import './style.css'
import {ReactComponent as Logo} from "../../assets/icon/logo.svg";
import {ReactComponent as LogoMobile} from "../../assets/icon/logo-mobile.svg";
import {ReactComponent as Prize} from "../../assets/icon/prize.svg";
import {ReactComponent as Person} from "../../assets/icon/person.svg";
import {ReactComponent as Сoupons} from "../../assets/icon/coupons.svg";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as Menu} from "../../assets/icon/burgerMenu.svg";
import {ReactComponent as Close} from "../../assets/icon/close.svg";
import Soc from "../footer/soc";
import Stores from "../footer/stores";
import Copy from "../footer/copy";
import Links from "../footer/links";
import {setPopup} from '../popups/store'
import {userSelector, winnersSelector} from "../../redux/auth";
import {getGifts} from "../../pages/Main/Winners/utils";

const Navigation = ({logo_link}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const mobile = useSelector(state => state.mobile)
  const [open, setOpen] = useState(false);
  const user = useSelector(userSelector)
  const winners = useSelector(winnersSelector)

  useEffect(() => {
    if (mobile && open) document.querySelector('body').style = 'overflow: hidden'
    if (!mobile || (mobile && !open)) document.querySelector('body').style = ''
  }, [open])

  useEffect(() => {
    if (typeof location.state !== 'undefined') {
      const element = document.getElementById(location.state.hash)
      setTimeout(function() {
        if (element) element.scrollIntoView({ behavior: "smooth" })
      }, 100);
    }
    else {
      window.scrollTo(0, 0)
    }
  }, [location])
  const gifts = getGifts(winners)
  const handleClick = () => {
    window.dataLayer.push({
      'event': 'registration_click', 
      'eventCategory': 'click', 
      'eventAction': 'registration_entrance'  
    })
    dispatch(setPopup('lk-login'))
  }
  return (
    <>
      <div className="header">
        {logo_link ? <Link to={{pathname:'/'}} className="header__logo notranslate">{mobile ? <LogoMobile /> : <Logo />}</Link> : <div className="header__logo notranslate">{mobile ? <LogoMobile /> : <Logo />}</div>}
        {!mobile && <div className="header__nav">
          <Link className="header__nav__item" to={{pathname:'/', state: {hash:'AboutPromotions'}}}><span>Об акции</span></Link>
          <Link className="header__nav__item" to={{pathname:'/', state: {hash:'Eligibility'}}}><span>Правила</span></Link>
          <Link className="header__nav__item" to={{pathname:'/', state: {hash:'PrizeFund'}}}><span>Призовой фонд</span></Link>
          {gifts.length > 0 && <Link className="header__nav__item" to={{pathname:'/', state: {hash:'Winners'}}}><span>Победители</span></Link>}
        </div>}
        <div className={`header__icons ${user ? '' : 'header__icons--signup'}`}>
          {/*user && <>
            <Link to={{pathname:'/lk', state: {hash:'prize'}}} className="header__icons__item"><Prize />{!mobile && <span>Мои призы</span>}</Link>}
            <Link to={{pathname:'/lk', state: {hash:'coupons'}}} className="header__icons__item"><Сoupons />{!mobile && <span>Мои купоны</span>}</Link>}
            <Link to='/lk'  className="header__icons__item"><Person />{!mobile && <span>{user.fullname}</span>}</Link>
          </>*/}
          {user && <Link to='/lk'  className="header__icons__item"><Person />{!mobile && <span>Личный кабинет</span>}</Link>}
          {!user && <div className="header__icons__item header__icons__item--signup" onClick={() => handleClick()}><Person />{!mobile && <span>Вход / Регистрация</span>}</div>}
          {mobile && !open && <div className="header__icons__item header__icons__item--menu"><Menu onClick={() => setOpen(!open)} /></div>}
          {mobile && open && <div className="header__icons__item header__icons__item--close"><Close onClick={() => setOpen(!open)} /></div>}
        </div>
      </div>
      {mobile && open && <div className="header__menu-mobile">
        <div className="header__menu-mobile__bg">
        <div className="header__nav-mobile">
          <Link className="header__nav-mobile__item" to={{pathname:'/', state: {hash:'AboutPromotions'}}} onClick={() => setOpen(!open)}><span>Об акции</span></Link>
          <Link className="header__nav-mobile__item" to={{pathname:'/', state: {hash:'Eligibility'}}} onClick={() => setOpen(!open)}><span>Правила</span></Link>
          <Link className="header__nav-mobile__item" to={{pathname:'/', state: {hash:'PrizeFund'}}} onClick={() => setOpen(!open)}><span>Призовой фонд</span></Link>
          {gifts.length > 0 && <Link className="header__nav-mobile__item" to={{pathname:'/', state: {hash:'Winners'}}} onClick={() => setOpen(!open)}><span>Победители</span></Link>}
          <Link className="header__nav-mobile__item" to={{pathname:'/', state: {hash:'Questions'}}} onClick={() => setOpen(!open)}><span>вопросы и ответы</span></Link>
          <Link className="header__nav-mobile__item" to="/wheel" onClick={() => setOpen(!open)}><span>Колесо фортуны</span></Link>
          <Link className="header__nav-mobile__item" to="/store-map" onClick={() => setOpen(!open)}><span>Карта магазинов</span></Link>
        </div>
        <div className="header__menu-mobile__bottom">
          {!user && <div className="page-btn" onClick={() => handleClick()}><span>Зарегистрироваться</span></div>}
          <Stores />
          <Soc />
          <div className="header__menu-mobile__bottom-info">
          <Copy />
          <Links />
          </div>
        </div>
        </div>
      </div>}
    </>
  );
};

export default Navigation;
