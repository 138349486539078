import React from 'react';

const Copy = () => {
  return (
    <div>
      <div className="footer-bottom-el">&copy; Афимолл Сити, 2021</div> <br/>
      123112, Москва г, Пресненская наб, дом 2
    </div>
  );
};

export default Copy;