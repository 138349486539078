// import axios from 'axios'
import mock from '../pages/StoreMap/mock'
import { createSelector } from 'reselect'

export const moduleName = 'storeMap'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const SET_FILTER = `${moduleName}/SET_FILTER`

const initialState = {
  loaded: false,
  items: [],
  text: '',
  limit: window.innerWidth < 1000 ? 2 : 6,
  search: ''
}

export default (state = initialState, action) => {
  const { type, response, payload } = action

  if(type === FETCH_REQUEST){
    return {
      ...state,
      ...response,
      loaded: true
    }
  }

  if(type === SET_FILTER){
    return {
      ...state,
      limit: payload.name === 'search' ? initialState.limit : state.limit,
      [payload.name]: payload.value,
    }
  }

  return state
}
//

export const loadedSelector = state => state[moduleName].loaded
export const itemsSelector = state => state[moduleName].items
export const limitSelector = state => state[moduleName].limit
export const textSelector = state => state[moduleName].text
export const searchSelector = state => state[moduleName].search

export const itemsLengthSelector = createSelector(
  itemsSelector,
  (items) => items.length
)
export const filtratedSelector = createSelector(
  itemsSelector,
  searchSelector,
  (items, search) => {
    return items.filter((el, i) => (!search || el.title.toLowerCase().includes( search.toLowerCase())))
  }
)
export const limitedSelector = createSelector(
  filtratedSelector,
  limitSelector,
  (items, limit) => items.filter((_, i) => i < limit)
)

//
export const fetchData = api => async dispatch => {

  dispatch({ type: FETCH_REQUEST, response: mock })

  /*await axios
      .get(api + '/ajax/json.php', { params: {page: 'store-map'} })
      .then(({ data }) => data)
      .then(response => dispatch({ type: FETCH_REQUEST, response }))*/
}

export const setFilter = (name, value) => ({type: SET_FILTER, payload: {name, value}})