import { useEffect } from 'react';
import Main from "../pages/Main/Main";
import {useDispatch, useSelector} from 'react-redux';
import { Route, Switch, Redirect } from "react-router-dom";
import StoreMapPage from  '../pages/StoreMap'
import Popups from '../components/popups';
import Lk from '../pages/lk'
import Wheel from '../pages/wheel';
import { isMobile } from '../redux/mobile';
import {checkedAuthorizedSelector, isAuthorized, userSelector} from "../redux/auth";
import PrivateRoute from './private-route'
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import './app.css'
import 'simplebar/dist/simplebar.min.css';
import {useImagePreload} from "./use-image-preload";
import img1 from '../components/ellipse/assets/ellipse-blue.png?2'
import img2 from '../components/ellipse/assets/ellipse-pink.png?2'
import img3 from '../pages/Main/ComingSoon/assets/comp.png?2'
import img4 from '../pages/Main/ComingSoon/assets/comp_mob.png?2'
import img5 from '../pages/Main/Winners/assets/bg.png?2'
import img6 from '../assets/images/lk-TV.png?2'
import img7 from '../assets/images/lk-fortune-wheel.png?2'
import img8 from '../pages/Main/Eligibility/assets/1.png?2'
import img9 from '../pages/Main/Eligibility/assets/2.png?2'

function App() {
  const dispatch = useDispatch();
  const mobile = useSelector(state => state.mobile)
  const user = useSelector(userSelector)
  const checkedAuthorized = useSelector(checkedAuthorizedSelector)
  const [loading] = useImagePreload([img1, img2, img3, img4, img5, img6, img7, img8, img9])

  useEffect(() => {
    dispatch(isAuthorized())
  }, [])

  useEffect(() => {
    const _resize = () => {
      const mob = window.innerWidth <= 1000
      if ((mob && !mobile) || (!mob && mobile)) dispatch(isMobile(mob))
    }

    dispatch(isMobile(window.innerWidth <= 1000))
    window.removeEventListener('resize', _resize);
    window.addEventListener('resize', _resize);

    return () => window.removeEventListener('resize', _resize);
  }, [mobile]);

  if (!checkedAuthorized || loading) return null


  const homePage = ({ location }) => {
    const referrer = location.state && location.state.referrer ? location.state.referrer : null
    if (user && referrer) return <Redirect to={referrer} />
    return <Main />
  }

  return (
    <>
      <div>
        <Switch>
          <Route path="/" exact component={homePage} />
          <PrivateRoute path="/lk" exact component={Lk} />
          <PrivateRoute path="/wheel" exact component={Wheel} />
          <Route path="/store-map" exact component={StoreMapPage} />
        </Switch>
      </div>
      <Popups />
    </>
  );
}

export default App;
