const PopupPrize = ({close}) => {
  const apply = () => {
    close();
  }

  return(
    <>
      <div className="popup-title">Где получить приз?</div>
      <div className="popup-dsc">2 этаж, центральный атриум, стойка выдачи призов</div>
      <div className="popup-prize-dsc">
        Период выдачи призов: 14 сентября – 30 ноября 2021 года<br /><br />
        Время выдачи призов:<br />
        понедельник-пятница с 14:00 до 22:00, перерыв с 17:20 до 18:00<br />
        суббота-воскресенье с 12:00 до 22:00
      </div>
      <img src="/img/popup/prize_plan.jpg" className="popup-prize-plan" />
      <div className="lk-button" onClick={apply}><span>понятно</span></div>
    </>
  )
}

export default PopupPrize;