import React from 'react';
import { Redirect, Route } from 'react-router-dom'
import {userSelector} from "../redux/auth";
import {useSelector} from "react-redux";

const PrivateRoute = ({  component: Component, location, ...rest }) => {
  const user = useSelector(userSelector)
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} />  : <Redirect to={{ pathname: '/', state: { referrer: location.pathname + location.search } }} />
      }
    />
  )
};

export default PrivateRoute;