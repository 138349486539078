const PopupError = ({close, errorText}) => {
  return(
    <>
      <div className="popup-title">{errorText ? errorText : 'Ошибка'}</div>
      {!errorText && <div className="popup-edit-dsc">Что-то пошло не так, попробуйте повторить позже</div>}
      <div className='lk-button' onClick={close}><span>Закрыть</span></div>
    </>
  )
}

export default PopupError;