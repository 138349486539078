import React from 'react';
import {Link} from "react-router-dom";

const Links = () => {
  return (
    <div>
      <div><a href="/docs/Afimall_rules.pdf?3" target="_blank" className="footer-bottom-el">Полные правила акции</a></div>
      Сделано в <a href="https://www.amio.ru/" target="_blank">A M I O</a>
    </div>
  );
};

export default Links;
