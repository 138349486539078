import React from "react";
import Questions from "../Questions/Questions";

import "./questionsSection.css";
import Ellipse from "../../../components/ellipse";
const questionsData = [
  {
    question: "Что такое баллы и как мне их накапливать?",
    answers:`Бонусные баллы начисляются за чеки суммой от 300 рублей. За
каждые 100 рублей в чеке начисляется 1 бонусный балл.
Скачайте мобильное приложение «Афимолл», Совершайте покупки
в магазинах, расположенных в ТРЦ «Афимолл», сканируйте чеки и
получайте баллы.`
  },
  {
    question: "Баллы действуют весь период акции?",
    answers:`Накопленные за одну акционную неделю баллы действуют в
течение одной акционной недели и могут быть обменены на купон
«Розыгрыш», который участвует в розыгрыше призов только этой
недели.`
  },
  {
    question: "Я накопил баллы, что дальше?",
    answers:`Накопив за одну неделю не менее 50 балов, вам нужно активировать
купон «Розыгрыш» стоимостью 50 баллов в разделе Комплименты
в мобильном приложении Афимолл Сити. Данный купон дает
возможность принять участие в розыгрыше суперпризов, который
проходит по понедельникам в 20:00 в прямом эфире RU.TV, а так же
получить гарантированный приз в розыгрыше дополнительных
призов «Колесо фортуны», если не удалось выиграть суперприз.`
  },
  {
    question: "Чем отличаются розыгрыши суперпризов от розыгрыша дополнительных призов «Колесо фортуны»?",
    answers:`Супер-розыгрыш проходит 1 раз в неделю в прямом эфире RU.TV,
чем больше вы накопите купонов к моменту розыгрыша за неделю,
тем больше шанс выиграть один из 10 суперпризов. Всего будет 10
розыгрышей (1 розыгрыш в неделю).
Розыгрыш дополнительных призов «Колесо фортуны» начинается
через 30 минут после окончания прямого эфира розыгрыша
суперпризов и длится неделю, до начала следующего. Вы можете
принять в нем участие, если не выиграли суперприз и получить
гарантированный подарок. Чем больше у вас купонов, тем дороже
призы вы можете выиграть. Принять участие в обоих розыгрышах
вы можете только один раз в неделю. Призеры розыгрыша
суперпризов не смогут принять участие в розыгрыше
дополнительных призов «Колесо фортуны».`
  },
  {
    question: "Когда проходит розыгрыш?",
    answers:`Розыгрыш суперпризов - <a href="/" class="schedule-lnk1">ознакомиться с расписанием</a><br />Розыгрыш дополнительных призов «Колесо фортуны» - <a href="/" class="schedule-lnk2">ознакомиться с расписанием</a>`
  },
  {
    question: 'Я могу участвовать в обоих розыгрышах?',
    answers: `Вы можете принимать участие в обоих розыгрышах один раз в
неделю и выиграть только один приз. При этом, призеры супер-
розыгрыша принять участие в розыгрыше дополнительных призов
«Колесо фортуны» не смогут, поскольку уже получили приз недели.`
  },
  {
    question: 'Я могу сканировать любые чеки?',
    answers: `В акции участвуют чеки на сумму не меньше 300 рублей из
торговых точек, расположенных в ТРЦ «Афимолл Сити», кроме
«Зеленый Перекресток», туристические компании, фитнес-клуб «5
элемент», аптеки, автомойки, отделения и точки финансовых и
банковских операций, оплата услуг парковки, оплата билетов
кинотеатра «КИНО ОККО», оплата услуг центра государственных
услуг «Мои документы», временные торговые ярмарки на
территории ТРЦ.`
  },
  {
    question: 'Что если я ввел разные номера телефонов на сайте при регистрации и в приложении?',
    answers: `Вам нужно перерегистрироваться на сайте или в мобильном
приложении, номера телефонов должны обязательно совпадать.`
  },
  {
    question: 'Как мне получить приз?',
    answers: `Призы выдаются в ТРЦ «Афимолл Сити» по адресу: Пресненская
        набережная д.2, второй этаж, центральный атриум, стойка выдачи
        призов.<br>
        Период выдачи призов: 14 сентября – 30 ноября 2021 года<br>
        Время выдачи призов:<br>
        понедельник-пятница с 14:00 до 22:00, перерыв с 17:20 до 18:00<br>
        суббота-воскресенье с 12:00 до 22:00`
  },
  {
    question: 'Нужно ли платить налоги за призы?',
    answers: `По общему правилу с приза стоимостью более 4000 рублей должен
быть уплачен НДФЛ (Пункт 1 ст. 210, пп. 2 п. 2 ст. 211 НК РФ ). Не
облагаются налогом лишь призы стоимостью в пределах 4000
рублей`
  },
  {
    question: 'Как связаться с службой поддержки?',
    answers: `Напишите нам на <a href="mailto:10years@afid.ru" target="_blank">10years@afid.ru</a>`
  },
  {
    question: 'Получу ли я суперприз, если не смотрел прямой эфир на телеканале RU.TV, но мой купон стал выигрышным?',
    answers: `Да. В течение суток после прямого эфира розыгрыша суперпризов
Вы получите смс с подтверждением о том, что Вы стали призером,
а так же информацию о призе в своем личном кабинете на сайте
акции. Запись прямого эфира Вы сможете посмотреть здесь
(ссылка на страничку You Tube «Афимолл Сити»)`
  },
  {
    question: 'Где посмотреть список победителей?',
    answers: '<a href="#Winners">Ссылка на список победителей</a>'
  }
];

export default function QuestionsSection() {
  return (
    <div className="questions" id="Questions">
      <div className="questions__bg">

        <Ellipse type='pink' className="questions__pink" />
        <Ellipse type='blue' className="questions__blue" />
        <div className="main-icon questions-icon4"><img src="/img/lk/lk2.png?2" width={200} height={200} /></div> {/*pink*/}
        <div className="main-icon questions-icon2"><img src="/img/lk/lk5.png?2" width={200} height={200} /></div> {/*yellow*/}
        <div className="main-icon questions-icon3"><img src="/img/lk/lk1.png?2" width={200} height={200} /></div> {/*blue*/}
      </div>
      <div className="questionsSection_Box">
        <div className="questionsSection_Box_Title">Вопросы и ответы</div>

        {questionsData.map((item, idx) => {
          return (
            <Questions
              key={idx}
              question={item.question}
              answers={item.answers}
            />
          );
        })}
      </div>
    </div>
  );
}
