import React from 'react';
import {ReactComponent as Appstore} from "../../assets/icon/appstore.svg";
import {ReactComponent as Googleplay} from "../../assets/icon/googleplay.svg";

const Stores = () => {
  return (
    <div className="footer-stores">
      <a href="https://itunes.apple.com/ru/app/%D0%B0%D1%84%D0%B8%D0%BC%D0%BE%D0%BB%D0%BB-%D1%81%D0%B8%D1%82%D0%B8/id880093759?mt=8" target="_blank" rel="noopener noreferrer"><Appstore /></a>
      <a href="https://play.google.com/store/apps/details?id=com.afimall.apps.android" target="_blank" rel="noopener noreferrer"><Googleplay /></a>
    </div>
  );
};

export default Stores;