import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  loadedSelector,
  fetchData,
  filtratedSelector,
  limitSelector,
  textSelector,
  itemsLengthSelector,
  setFilter, limitedSelector
} from '../../redux/store-map'
import Navigation from "../../components/Navgation/Navigation";
import Footer from "../../components/footer";
import './style.css'
import Search from "./search";
import Item from "./item";

const StoreMapPage = () => {
  const dispatch = useDispatch()
  const {
    loaded,
    filtrated,
    limited,
    mobile,
    text
  } = useSelector(state => ({
    loaded: loadedSelector(state),
    filtrated: filtratedSelector(state),
    limited: limitedSelector(state),
    text: textSelector(state),
    mobile: state.mobile,
  }))
  const [col, setCol] = useState([[],[],[]])

  useEffect(() => {
    if (!loaded) dispatch(fetchData())
  }, [])
  useEffect(() => {
    if (!loaded) return false
    const arr = [[],[],[]]
    if (mobile) return setCol([limited, [], []])

    limited.map((el, i) => {
      if ((i+1)%3===0) arr[2].push(el)
      else if ((i-1)%3===0) arr[1].push(el)
      else arr[0].push(el)
    })
    setCol(arr)
  }, [limited, mobile])


  if (!loaded) return null

  return (
    <div className="store">
      <Navigation logo_link={true} />


      <div className="store-bg1" />
      <div className="store-content">
        <div className="store-bg2" />
        <div className="store-bg3" />
        <div className="store-bg4" />
        <div className="store-icon store-icon1"><img src="/img/lk/lk5.png?2" /></div>
        <div className="store-icon store-icon2"><img src="/img/lk/lk2.png?2" /></div>
        <div className="store-icon store-icon3"><img src="/img/lk/lk1.png?2" /></div>

        {!mobile && <div className="store-icon store-icon4"><img src="/img/lk/lk2.png?2" /></div>}
        {!mobile && <div className="store-icon store-icon5"><img src="/img/lk/lk1.png?2" /></div>}
        {!mobile && <div className="store-icon store-icon6"><img src="/img/lk/lk5.png?2" /></div>}
        <div className="store-content__bg">
          <span/>
          <span/>
          <span/>
          <span/>
        </div>
        <div className="store-content__in">
          <div className="store-title">Карта магазинов</div>
          <div className="store-subtitle" dangerouslySetInnerHTML={{__html: text}} />
          <Search />

          <div className="store-grid">
            <div className="store-grid__column">
              {col[0].map(el => <Item el={el}/>)}
            </div>
            <div className="store-grid__column">
              {col[1].map(el => <Item el={el}/>)}
            </div>
            <div className="store-grid__column">
              {col[2].map(el => <Item el={el}/>)}
            </div>
          </div>
          {limited.length < filtrated.length && <div className="store-btn" onClick={() => dispatch(setFilter('limit', filtrated.length))}><span>показать еще</span></div>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StoreMapPage;
