import React from "react";
import Timer from "../../../components/Timer/Timer";
import "./comingSoon.css";
import {useDispatch, useSelector} from "react-redux";
import {setPopup} from "../../../components/popups/store";
import {userSelector} from "../../../redux/auth";
import {Link} from "react-router-dom";

export default function ComingSoon() {
  const user = useSelector(userSelector)
  const dispatch = useDispatch()
  const handleClick = () => {
    window.dataLayer.push({
      'event': 'registration_click', 
      'eventCategory': 'click', 
      'eventAction': 'registration_entrance'  
    })
    dispatch(setPopup('lk-login'))
  }
  return (
    <div className="coming_Soon_Box">
      <div className="comp" />
      <div className="coming_Soon_Timer">
        <span />
        <Timer />
      </div>
      <p className="comin_Soon_Promo">
        беспроигрышная акция <br /> с 6 сентября по 14 ноября 2021
      </p>
      <p className="coming_Soon_Contect"> Шоппинг марафон </p>
      <p className="comin_Soon_Title">«Афимоллу 10 лет!»</p>
      <p className="coming_Soon_Limit">10 недель — 10 розыгрышей</p>
      {!user && <div className="page-btn coming_Soon_Btn" onClick={() => handleClick()}><span>Зарегистрироваться</span></div>}
      {user && <Link className="page-btn coming_Soon_Btn" to={{pathname:'/', state: {hash:'Eligibility'}}}><span>Участвовать</span></Link>}
    </div>
  );
}
