const PopupPrize = ({close}) => {
  const apply = () => {
    close();
  }
  const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
  const d = new Date();
  d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7));

  return(
    <>
      <div className="popup-title">Вы успешно зарегистрировались в акции "Афимолу 10 лет"</div>
      <div className="popup-dsc">Розыгрыш стартует {d.getDate()} {months[d.getMonth()]} {d.getFullYear()}</div>
      <div className="popup-prize-dsc"></div>
      <div className="lk-button" onClick={apply}><span>понятно</span></div>
    </>
  )
}

export default PopupPrize;