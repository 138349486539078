import { useEffect, useState } from 'react';
import "./timer.css";

export default function Timer() {
  const dates = [
    (new Date(2021, 8, 13, 20, 0, 0)).getTime(),
    (new Date(2021, 8, 20, 20, 0, 0)).getTime(),
    (new Date(2021, 8, 27, 20, 0, 0)).getTime(),
    (new Date(2021, 9, 4, 20, 0, 0)).getTime(),
    (new Date(2021, 9, 11, 20, 0, 0)).getTime(),
    (new Date(2021, 9, 18, 20, 0, 0)).getTime(),
    (new Date(2021, 9, 25, 20, 0, 0)).getTime(),
    (new Date(2021, 10, 1, 20, 0, 0)).getTime(),
    (new Date(2021, 10, 8, 20, 0, 0)).getTime(),
    (new Date(2021, 10, 15, 20, 0, 0)).getTime(),
  ]
  const time = (new Date()).getTime();
  const date = dates.filter(function(it, i) {return (it > time)})[0]

  const [count, setCount] = useState({d: '', h: '', m: '', s: ''});
  useEffect(() => {
    let interval
    if (date) {
      interval = setInterval(function() {
        const time = (new Date()).getTime();
        const diff = Math.round((date - time) / 1000);
        let d = Math.floor(diff / (60 * 60 * 24));
        if (d.toString().length < 2) {
          d = '0' + d;
        }
        let h = Math.floor((diff - d * 60 * 60 * 24) / (60 * 60));
        if (h.toString().length < 2) {
          h = '0' + h;
        }
        let m = Math.floor((diff - d * 60 * 60 * 24 - h * 60 * 60) / 60);
        if (m.toString().length < 2) {
          m = '0' + m;
        }
        let s = Math.floor(diff - d * 60 * 60 * 24 - h * 60 * 60 - m * 60);
        if (s.toString().length < 2) {
          s = '0' + s;
        }
        setCount({d: d, h: h, m: m, s: s})
      }, 1000);
    }
    return () => clearInterval(interval);
  }, []);

  const declOfNum = (number, titles) => {
    const cases = [2, 0, 1, 1, 1, 2]
    number = parseInt(number)
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
  }

  return (
    <div className={'timer_Box' + ((count.d || count.h || count.h || count.s) ? ' started' : '')}>
      <div className='timer_Item_First_Block'>До супер-розыгрыша осталось</div>
      <div className='timer_Item_Last_Block'>
        <div>
          <span className='tim_Item'>
            {count.d}
            <span className='item'>{declOfNum(count.d, ['день','дня','дней'])}</span>
          </span>
          <span className='tim_Item'>
            {count.h}
            <span className='item'>{declOfNum(count.h, ['час','часа','часов'])}</span>
          </span>
          <span className='tim_Item'>
            {count.m}
            <span className='item'>{declOfNum(count.m, ['минута','минуты','минут'])}</span>
          </span>
          <span className='tim_Item'>
            {count.s}
            <span className='item'>{declOfNum(count.s, ['секунда','секунды','секунд'])}</span>
          </span>
        </div>
      </div>
    </div>
  );
}
