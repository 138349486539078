export default {
  text: 'При покупке в данных магазинах<br />вы получите в 2 раза больше баллов',
  items: [
    {
      id: 1,
      logo: '/img/logo/1.png?2',
      title: 'Карат',
      floor: '2 этаж',
      text1: 'Ювелирный бренд «КАРАТ» имеет 20-летнюю историю и заслуженную репутацию одного из лучших ювелирных брендов в стране. Основной партнер ювелирного бренда — Белгородский ювелирный завод «Арт-КАРАТ».',
      text2: 'Собственное производство, основанное в 2017 году, в рекордные сроки заняло уверенные позиции среди крупнейших производителей ювелирных изделий, известных не только в России, но и за рубежом. Ювелирные украшения из золота и серебра от завода-изготовителя, лучшее качество и лучшие цены — всё это гарантирует ювелирный магазин «КАРАТ».'
    },
    {
      id: 2,
      logo: '/img/logo/2.png?2',
      title: 'L’Etoile/ Л’Этуаль',
      floor: '2 этаж',
      text1: 'Л’Этуаль — лидер парфюмерно-косметической индустрии в России. Вот уже 23 года мы радуем клиентов лучшим сервисом и лучшим ассортиментом, профессиональными рекомендациями и экспертным подходом. У нас 1000 магазинов от Калининграда до Владивостока, и каждый из них — комфортное пространство для увлекательного бьюти-шопинга.',
      text2: 'Нас любят клиенты и партнеры, мы на «ты» со всей бьюти-индустрией, а наши посты в Инстаграме собирают миллионы лайков и просмотров. «На одной волне с тобой!» — так звучит слоган нашей компании. Это значит, что мы всегда в тренде и чувствуем, что важно каждому из вас. Мы учитываем и воплощаем желания клиентов во всех решениях компании.',
    },
    {
      id: 3,
      logo: '/img/logo/3.png?2',
      title: 'The Body Shop',
      floor: '1 этаж',
      text1: 'Всемирно известный этичный бренд The Body Shop был основан Анитой Роддик в 1976 г. в Великобритании. Компания производит средства для ухода за кожей с использованием питательных масел и растительных экстрактов, закупаемых в разных уголках земного шара по программе «Справедливая торговля».',
      text2: 'В ассортимент бренда входит косметика по уходу за кожей лица и тела, косметика по уходу за волосами, декоративная косметика, парфюмерия, косметика для мужчин, а также разнообразные аксессуары (кисти для макияжа, аксессуары для лица, тела и волос). The Body Shop стал первым брендом в индустрии красоты, предложившим новую философию ведения бизнеса, согласно которой коммерческий успех должен идти рука об руку с активной социальной деятельностью и сохранением окружающей среды.'
    },
    {
      id: 4,
      logo: '/img/logo/4.png?2',
      title: 'Ikea',
      floor: '1 этаж',
      text1: 'Сегодня у бренда ИКЕА миллионы поклонников, а наши товары покупают по всему миру. Но у каждой истории успеха есть свое начало. Вы знали, что когда-то мы были крошечным магазином в Эльмхульте, небольшой деревушке в сельском районе Швеции, а продажи осуществлялись через каталог для заказа по почте?',
      text2: 'Наша миссия — изменить к лучшему повседневную жизнь многих людей – не только покупателей, но и сотрудников компании ИКЕА и компаний-поставщиков. В компании ИКЕА мы считаем, что хороший дизайн должен быть доступен всем, поэтому мы называем наш подход демократичным дизайном. Как же нам удается производить товары, сочетающие в себе и экологичность, и форму, и функциональность, и качество по низкой цене? Дело в том, что принципы разработки наших товаров можно выразить одним вопросом, который мы постоянно себе задаем: «Можно ли сделать лучше?»'
    },
    {
      id: 5,
      logo: '/img/logo/5.png?2',
      title: 'Kari ',
      floor: '1 этаж',
      text1: 'Kari − это №1 в России по продажам обуви и самая большая сеть розничных магазинов. <p>Если обувь, то Kari!</p>',
      text2: 'Под торговой маркой Kari работают более 1300 магазинов. Компания реализует концепцию fast-fashion – предлагает своим покупателям разнообразный, постоянно обновляющийся ассортимент модной обуви и аксессуаров для мужчин, женщин и детей.'
    },
    {
      id: 6,
      logo: '/img/logo/6.png?2',
      title: 'Lemongrass House',
      floor: '2 этаж',
      text1: 'Lemongrass House  - это ручное производство СПА-косметики высочайшего качества в Таиланде на о.Пхукет. Секрет признания бренда такими отелями и SPA мирового уровня, как: Aman Resorts, Four Seasons Hotels, Le Meridien Hotels и т.д. – это неизменное качество, высокая концентрация эфирных масел, ручное смешивание компонентов и уникальный ароматерапевтический эффект от каждого продукта.',
      text2: 'В рецептуре используются только растительные и природные ингредиенты. <br/> Теперь вы можете насладиться этой чудесной косметикой и ароматами не выходя из дома! <br/> Окунитесь в мир 5* SPA c Lemongrass House!'
    },
    {
      id: 7,
      logo: '/img/logo/7.png?2',
      title: 'Vittoria Vicci',
      floor: '1 этаж',
      text1: 'Уникальность коллекций VITTORIA VICCI заключается в понимании желаний и потребностей российских девушек и женщин.',
      text2: 'Качество, удобство, стиль, цена - основные критерии используемые в коллекциях VITTORIA VICCI. <br/>Покупательница одежды VITTORIA VICCI молода, женственна, элегантна, чувственна и сексуальна, любит быть в центре внимания и вызывать восхищение.'
    },
    {
      id: 8,
      logo: '/img/logo/8.png?2',
      title: 'Togas',
      floor: '1 этаж',
      text1: 'Современный дом немыслим без правильного текстиля. А последний невозможно представить без Togas — одного из главных текстильных экспертов нашего времени. Togas – это европейский дом со столетней историей, который производит продукты для сна, лучший текстиль и аксессуары для дома.',
      text2: 'Вдохновленные историей и традициями разных стран, культурой, искусством и современными трендами дизайнеры Togas разрабатывают широчайший ассортимент постельного белья, изделий из махровых тканей, спальных принадлежностей, столового текстиля, штор, посуды, домашней одежды и ароматов для дома. <br/>Используя ткани и материалы высочайшего качества, Togas создает уникальные коллекции текстиля для дома на производствах по всему миру c соблюдением строжайших требований экологического контроля и современных стандартов производства. '
    },
    {
      id: 9,
      logo: '/img/logo/9.png?2',
      title: 'Космик',
      floor: '4 этаж',
      text1: 'Парки развлечений Космик - это отличный отдых для всей семьи! Огромные игровые зоны с невероятными аттракционами, видеоиграми, лазертагом, разнообразными автоматами – для взрослых и детей любого возраста! Современный боулинг, оснащенный новейшим оборудованием от лидеров мировой боулинг индустрии.',
      text2: 'Просторные бильярдные залы со столами для игры в русскую пирамиду и американский пул<br/>Спортбары с лучшими мировыми спортивными трансляциями, а также семейные рестораны <br/>Космик - это незабываемые вечеринки, праздники, новые впечатления и счастливые мгновения рядом с друзьями и близкими!<br/>В наших центрах можно не просто весело провести время, но и отпраздновать важнейшие события. Детские праздники, Дни рождения, корпоративные мероприятия – мы сделаем любое событие запоминающимся!'
    },
    {
      id: 10,
      logo: '/img/logo/10.png?2',
      title: 'МЮЗ',
      floor: '2 этаж',
      text1: 'MIUZ DIAMONDS – одно из старейших ювелирных предприятий России. За время существования высококвалифицированными специалистами «MIUZ DIAMONDS» накоплен огромный опыт в искусстве создания ювелирных украшений, который передаётся молодому поколению для сохранения традиций и уникального стиля с использованием современных технологий. ',
      text2: 'Бриллианты меняют все!'
    },
    {
      id: 11,
      logo: '/img/logo/11.png?2',
      title: 'Plumage ',
      floor: '1 этаж',
      text1: 'PLUMAGE — это проект выходного платья и костюма… <br/>…и всего, что связано с понятием «выхода в свет».',
      text2: 'Лучшие вечерние платья, платья для мам и подружек невесты; стильные коктейльные платья для вечеринок и свиданий; изысканные свадебные платья от известнейших дизайнеров; модные выпускные платья; актуальные нарядные деловые платья; элегантные мужские выходные костюмы; специальная коллекция больших размеров; супер-профессиональная подгонка по фигуре; компетентная и отзывчивая команда консультантов-стилистов;'
    },
    {
      id: 12,
      logo: '/img/logo/12.png?2',
      title: 'Золотое яблоко ',
      floor: '2 этаж',
      text1: '«Золотое Яблоко» – сеть флагманских парфюмерно-косметических магазинов. В магазинах сети представлен огромный ассортимент из более чем 1 500 брендов уходовой и культовой декоративной косметики. ',
      text2: 'Здесь собраны редкие селективные ароматы и профессиональные средства для волос, прогрессивные бренды из Кореи и Японии и бренды, эксклюзивно представленные в сети. У сети «Золотое Яблоко» удобная система лояльности, и популярное мобильное приложение, которое заняло первое место в рейтинге мобильных приложений на ios в категории «шопинг».'
    },
    {
      id: 13,
      logo: '/img/logo/13.png?2',
      title: 'Инсам',
      floor: '5 этаж',
      text1: 'Ресторан «Инсам» - это богатство гастрономических, корейских изысков. Свежайшие, тщательно отобранные продукты, оригинальные рецептуры, аппетитная подача и аутентичная обстановка ждут Вас! ',
      text2: 'Ресторан с удовольствием устроит для Вас экскурсию по корейским городам и предложит лучшие блюда Южной Кореи. <br/>Мы с удовольствием подготовим для Вас банкет или детский праздник с эксклюзивным меню, а также забронируем столиков на желаемое количество человек.'
    },
    {
      id: 14,
      logo: '/img/logo/14.png?2',
      title: 'Erborian',
      floor: '2 этаж',
      text1: 'Корейско-французский косметический бренд Erborian – это уникальное сочетание корейских ингредиентов и текстур с французским искусством создавать технологичные, эффективные и безопасные средства. ',
      text2: 'В основе каждого из наших продуктов лежат инновации и многовековой опыт корейской традиционной фармакологии. Название бренда Erborian происходит от французского "Herbes d\'Orient", что означает «травы Востока». Erborian раскрывает секрет совершенной кожи!'
    },
    {
      id: 15,
      logo: '/img/logo/15.png?2',
      title: 'ЭПЛ Якутские бриллианты ',
      floor: '2 этаж',
      text1: 'Сегодня ЭПЛ — это группа компаний, объединяющая собственное гранильное производство, собственное ювелирное производство и собственную розничную сеть ювелирных салонов.<br/>Самый широкий выбор ювелирных изделий позволяют бренду предлагать как элитарные изделия для самого изысканного приема, так и более доступные, но не менее роскошные варианты изделий с бриллиантами.',
      text2: 'Ювелиры, художники мирового бренда ЭПЛ создают образцы высочайшего ювелирного искусства, мгновенно становящиеся современной классикой. Каждое изделие – это история, особое настроение и оригинальный взгляд. <br/> Выбирая бренд ЭПЛ вы становитесь частью волшебного мира мечты, символом которого является главная цель Компании – принести счастье каждой девушке мира.'
    },
    {
      id: 16,
      logo: '/img/logo/16.png?2',
      title: 'Samsonite',
      floor: '3 этаж',
      text1: 'Компания Samsonite, основанная в 1910 году, является ведущим мировым производителем чемоданов и известна своими революционными решениями, а также приверженностью инновациям и устойчивому развитию.',
      text2: 'С момента своего создания, Samsonite преуспела во многих отраслевых новинках и предлагает широкий ассортимент туристических, деловых, детских, повседневных и личных аксессуаров. Samsonite вдохновляет путешественников всего мира на новые свершения, предлагая все более легкие и прочные товары.'
    },
    {
      id: 17,
      logo: '/img/logo/17.png?2',
      title: 'РИВ ГОШ',
      floor: '2 этаж',
      text1: 'Сеть магазинов РИВ ГОШ является одной из крупнейших федеральных сетей магазинов парфюмерии и косметики, существует на рынке с 1995 года. Сегодня компания насчитывает более 200 магазинов по всей России. Каждый месяц в магазинах РИВ ГОШ тысячи покупателей становятся участниками необыкновенных акций, получают приглашения на уникальные мероприятия и клиентские дни.',
      text2: 'РИВ ГОШ — это самые последние новинки, первоклассные консультанты, отличные цены, великолепный выбор подарков и прекрасное настроение!'
    },
    {
      id: 18,
      logo: '/img/logo/18.png?2',
      title: 'Uniqlo',
      floor: '4 этаж',
      text1: 'Бренд UNIQLO принадлежит японскому холдингу Fast Retailing Co., Ltd. – мировому лидеру в сфере розничной торговли. ',
      text2: 'Компания занимается дизайном, производством и продажей одежды. UNIQLO разрабатывает, создает, продвигает и продает повседневную одежду высокого качества. Компания верит, что по-настоящему превосходная одежда должна быть очень удобной и качественной, иметь универсальный дизайн и идеальную подсадку по фигуре. Вооружившись лозунгом «Меняя одежду, меняя стереотипы мышления, изменяем мир», компания Fast Retailing стремится создавать высококачественную одежду нового уровня, чтобы добавить новые краски в жизни людей во всем мире. В нашем магазине вы найдете женскую, мужскую и детскую одежду абсолютно на все случаи жизни.'
    },
    {
      id: 19,
      logo: '/img/logo/19.png?2',
      title: 'VR Park',
      floor: '6 этаж',
      text1: 'Крупнейший парк в Москве с технологией виртуальной реальности и разными площадками Polygon и Play VR. ',
      text2: 'Новые технологии и современное оборудование помогут воплотить ваши желания и мечты в реальность, увлекательно и разнообразно провести мероприятия и получить самые невероятные эмоции и впечатления. Арена Polygon работает на сверхточных камерах OptiTrack с системой захвата движения. Датчики и игровая консоль крепятся на тело, что позволяет свободно перемещаться по площадке в 120м. Play VR - игровые залы на базе HTC Vive, в комплект входят шлемы и контроллеры. Играйте более чем в 40 игр, большинство из которых доступны для мультиплеера.'
    },
    {
      id: 20,
      logo: '/img/logo/20.png?2',
      title: 'GOLDTEX',
      floor: '2 этаж',
      text1: 'GOLDTEX - это постельное белье, подушки, одеяла и другой домашний текстиль премиального качества. Основанная в 1999 году, приоритетом производства Компания определила создание действительно качественных текстильных изделий, и мы можем уверенно сказать, что у нас это получилось.',
      text2: 'За время работы наш ассортимент был расширен - кроме постельных принадлежностей, в GOLDTEX Вы можете приобрести стильные домашние костюмы для женщин, мягкие, теплые и красивые пледы, уютные махровые халаты и полотенца. Наполнить атмосферу дома ощущением силы, страсти и утонченной роскоши помогут ароматы, предлагаемые в нашем бутике. <br/>Мы делаем все, чтобы Ваш сон был комфортным, а интерьер - красивым.'
    },
    {
      id: 21,
      logo: '/img/logo/21.png?2',
      title: 'НИКА',
      floor: '2 этаж',
      text1: 'НИКА – ведущий производитель ювелирных часов в России с 2003 года. На часовом заводе в Москве НИКА создает драгоценные часы и аксессуары из золота 585° и серебра 925°, украшая их бриллиантами и фианитами.',
      text2: 'Обновление модельного ряда и выпуск лимитированных коллекций происходит каждый сезон. НИКА отличается собственными запатентованными разработками и технологиями.'
    },
    {
      id: 22,
      logo: '/img/logo/22.png?2',
      title: 'Пират Мармелад',
      floor: '2 этаж',
      text1: 'Друзья, приглашаем вас на борт нашего магазина! <br/>Трюмы нашего корабля полны сладостей со всего мира на любой вкус.<br/>Сладкоежки и любители необычных вкусов найдут лакомства из более 200 видов, представленных в магазине. И это только начало: широкая палитра вкусов дополнена оригинальными яркими формами, цветами и дизайном наших сладостей.',
      text2: 'Все эти сокровища хранятся в доверху наполненных бочках и сундуках с вкуснейшим мармеладом, суфле, зефиром, шоколадными орешками, ирисками, маршмеллоу и, конечно, нашей уникальной карамелью ручной работы, процесс изготовления которой можно увидеть прямо в магазине. <br/>Доставьте себе удовольствие, порадовав себя и своих близких изумительными, необыкновенными вкусностями. Зарядитесь позитивом и яркими эмоциями на весь день! '
    },
    {
      id: 23,
      logo: '/img/logo/23.png?2',
      title: 'СТИЛИСТИКА',
      floor: '2 этаж',
      text1: 'Сеть студий красоты СТИЛИСТИКА объединила в себе команду профессионалов-единомышленников, влюбленных в свое дело.',
      text2: 'Обучение в лучших академиях индустрии красоты позволяет нашим мастерам быть в курсе последних модных тенденций. Концепция сети – обеспечивать индивидуальный подход при доступных ценах. СТИЛИСТИКА сотрудничает с лучшими мировыми брендами и использует современное оборудование, а все инструменты проходят тщательную санитарную обработку и стерилизацию перед каждым клиентом.'
    },
    {
      id: 24,
      logo: '/img/logo/24.png?2',
      title: 'ЦУМ Outlet',
      floor: '1 этаж',
      text1: 'ЦУМ Outlet– сеть магазинов, предлагающих товары класса «люкс» с привлекательными скидками до 70%. В магазине представлены более 200 премиальных мировых брендов мужской и женской одежды, обуви и аксессуаров.',
      text2: 'Профессиональные консультанты помогают создать подходящий образ для любого случая. Для постоянных покупателей работает программа лояльности: до 10% от стоимости покупок накапливается на картах ЦУМ Outlet в виде бонусных баллов, которые можно использовать для оплаты новых покупок.'
    },
  ],
}