import React from "react";
import { Link } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { setPopup } from "../../../components/popups/store";
import "./pointsSystem.css";
import ikea from "./images/ikea.png?2";
import bodyShop from "./images/bodyShop.png?2";
import epa from "./images/epa.png?2";
import erborian from "./images/erborian.png?2";
import goldApple from "./images/goldApple.png?2";
import letual from "./images/letual.png?2";
import miuz from "./images/miuz.png?2";
import rivGosh from "./images/rivGosh.png?2";
import sams from "./images/sams.png?2";
import togo from "./images/togo.png?2";
import uni from "./images/uni.png?2";
import Ellipse from "../../../components/ellipse";
import {userSelector} from "../../../redux/auth";
import Timer from "../../../components/Timer/Timer";

export default function PointsSystem() {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const handleClick = () => {
    window.dataLayer.push({
      'event': 'registration_click', 
      'eventCategory': 'click', 
      'eventAction': 'registration_entrance'  
    })
    dispatch(setPopup('lk-login'))
  }
  return (
    <>
    <div className="points">
      <div className="points__bg">
        <Ellipse type='pink' className="points__pink" />
        <Ellipse type='blue' className="points__blue" />
        <div className="main-icon points-icon2"><img src="/img/lk/lk2.png?2" width={200} height={200} /></div> {/*pink*/}
        <div className="main-icon points-icon3"><img src="/img/lk/lk5.png?2" width={200} height={200} /></div> {/*yellow*/}
        <div className="main-icon points-icon4"><img src="/img/lk/lk1.png?2" width={200} height={200} /></div> {/*blue*/}

          <div className="points__icon points__icon--1">
            <img src={ikea} alt="ikea" width={349} height={359} />
          </div>
          <div className="points__icon points__icon--2">
            <img src={letual} alt="letual" width={349} height={359} />
          </div>
          <div className="points__icon points__icon--3">
            <img src={miuz} alt="miuz" width={349} height={349} />
          </div>
          <div className="points__icon points__icon--4">
            <img src={bodyShop} alt="bodyShop" width={349} height={359} />
          </div>
          <div className="points__icon points__icon--5">
            <img src={uni} alt="uni" width={349} height={359} />
          </div>
          <div className="points__icon points__icon--6">
            <img src={togo} alt="togo" width={349} height={359} />
          </div>
          <div className="points__icon points__icon--7">
            <img src={epa} alt="epa" width={349} height={359} />
          </div>
          <div className="points__icon points__icon--8">
            <img src={erborian} alt="erborian" width={349} height={359} />
          </div>
          <div className="points__icon points__icon--9">
            <img src={goldApple} alt="goldApple" width={349} height={359} />
          </div>
          <div className="points__icon points__icon--10">
            <img src={rivGosh} alt="rivGosh" width={349} height={359} />
          </div>
          <div className="points__icon points__icon--11">
            <img src={sams} alt="sams" width={359} height={359} />
          </div>
        </div>
        <div className="points__text">
          <div className="points__text__title">
            Получите в 2 раза больше баллов <br /> в&nbsp;магазинах&#8209;партнерах
          </div>
          <Link className="page-btn" to={{pathname:'/store-map'}}><span>Список магазинов</span></Link>
        </div>
      </div>

      <div className="eligibility_Footer_Box_Wrapper points__eligibility">
        <div className="eligibility_Footer_Box_Wrapper__bg eligibility_Footer_Box_Wrapper__bg--1" />
        <div className="eligibility_Footer_Box">
          {user
            ? <div>Успейте участвовать <br/> в розыгрыше призов</div>
            : <div>Успейте зарегистрироваться <br/> на розыгрыш призов</div>
          }
          {user
            ? <Timer />
            : <span className="page-btn" onClick={() => handleClick()}><span>Зарегистрироваться</span></span>
          }
        </div>
        <div className="eligibility_Footer_Box_Wrapper__bg eligibility_Footer_Box_Wrapper__bg--2" />
      </div>
    </>
  );
}
