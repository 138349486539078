import {useEffect, useState} from 'react';
import auth, {profileUpdate} from "../../redux/auth";
import {useDispatch, useSelector} from "react-redux";
// import InputMask from 'react-input-mask';

const PopupEdit = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    setName(user.fullname)
    setEmail(user.email)
  }, [user])

  const check = () => name

  const apply = () => {
    if(check()) {
      const formData = new FormData()
      formData.append('fullname', name)
      if (email) formData.append('email', email)
      dispatch(profileUpdate(formData))
    }
  }
  if (!user) return null

  return(
    <>
      <div className="popup-title">Введите ваши данные</div>
      <div className="input"><input placeholder="Имя Фамилия *" value={name} onChange={e => setName(e.target.value)} /></div>
      {/* <div className="input">
        <InputMask placeholder="Телефон *" mask="+7 (999) 999-99-99" onChange={e => setPhone(e.target.value)} value={phone} />;
      </div> */}
      <div className="input"><input placeholder="Электронная почта" value={email} onChange={e => setEmail(e.target.value)} /></div>
      <div className="popup-edit-dsc">* поля обязательные для заполнения</div>
      <div className={!check() ? 'lk-button disabled' : 'lk-button'} onClick={apply}><span>сохранить</span></div>
    </>
  )
}

export default PopupEdit;