import {Link} from 'react-router-dom'

const PopupDoubleMsg = ({close}) => {
  return(
    <>
      <div className="popup-dsc">Получите в 2 раза больше баллов в магазинах партнера</div>
      <Link to="/store-map" className="lk-button" onClick={close}>список магазинов</Link>
    </>
  )
}

export default PopupDoubleMsg;