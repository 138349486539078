import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPopup  } from '../../components/popups/store';
import { ReactComponent as Close } from "../../assets/icon/popup_close.svg";
import PopupEdit from './PopupEdit';
import PopupPromocode from './PopupPromocode';
import PopupPrize from './PopupPrize';
import PopupLogin from './PopupLogin';
import './style.css';
import PopupSms from "./PopupSms";
import PopupError from './PopupError'
import PopupShedule1 from './PopupShedule1';
import PopupShedule2 from './PopupShedule2';
import {errorTextSelector} from "../../redux/auth";
import PopupSignupSuccess from "./PopupSignupSuccess";
import PopupNotStarted from "./PopupNotStarted";
import PopupDoubleMsg from "./PopupDoubleMsg";

const Popups = () => {
  const dispatch = useDispatch();
  const popups = useSelector(state => state.popups);
  const smsSend = useSelector(state => state.auth.smsSend)
  const errorText = useSelector(errorTextSelector)

  useEffect(() => {
    if(popups){
      document.body.classList.add('show-popup');
    }
    else{
      document.body.classList.remove('show-popup');
    }
  },[popups]);

  return(
    <>
      {(popups === 'lk-signup-success') && (
        <div className="popup">
          <div className="popup-bg" onClick={() => dispatch(setPopup(null))} />
          <div className="popup-in popup-in-prize">
            <div className="popup-close" onClick={() => dispatch(setPopup(null))}><Close /></div>
            <PopupSignupSuccess close={() => dispatch(setPopup(null))} />
          </div>
        </div>
      )}
      {(popups === 'lk-sms' || (popups === 'lk-login' && smsSend)) && (
        <div className="popup">
          <div className="popup-bg" onClick={() => dispatch(setPopup(null))} />
          <div className="popup-in">
            <div className="popup-close" onClick={() => dispatch(setPopup(null))}><Close /></div>
            <PopupSms close={() => dispatch(setPopup(null))} />
          </div>
        </div>
      )}
      {(popups === 'lk-login' && !smsSend)  && (
        <div className="popup">
          <div className="popup-bg" onClick={() => dispatch(setPopup(null))} />
          <div className="popup-in">
            <div className="popup-close" onClick={() => dispatch(setPopup(null))}><Close /></div>
            <PopupLogin close={() => dispatch(setPopup(null))} />
          </div>
        </div>
      )}
      {popups === 'lk-edit' && (
        <div className="popup">
          <div className="popup-bg" onClick={() => dispatch(setPopup(null))} />
          <div className="popup-in">
            <div className="popup-close" onClick={() => dispatch(setPopup(null))}><Close /></div>
            <PopupEdit close={() => dispatch(setPopup(null))} />
          </div>
        </div>
      )}
      {popups === 'lk-promocode' && (
        <div className="popup">
          <div className="popup-bg" onClick={() => dispatch(setPopup(null))} />
          <div className="popup-in">
            <div className="popup-close" onClick={() => dispatch(setPopup(null))}><Close /></div>
            <PopupPromocode close={() => dispatch(setPopup(null))} />
          </div>
        </div>
      )}
      {popups === 'lk-prize' && (
        <div className="popup">
          <div className="popup-bg" onClick={() => dispatch(setPopup(null))} />
          <div className="popup-in popup-in-prize">
            <div className="popup-close" onClick={() => dispatch(setPopup(null))}><Close /></div>
            <PopupPrize close={() => dispatch(setPopup(null))} />
          </div>
        </div>
      )}
      {popups === 'lk-error' && (
        <div className="popup">
          <div className="popup-bg" onClick={() => dispatch(setPopup(null))} />
          <div className="popup-in popup-in-prize">
            <div className="popup-close" onClick={() => dispatch(setPopup(null))}><Close /></div>
            <PopupError close={() => dispatch(setPopup(null))} errorText={errorText} />
          </div>
        </div>
      )}
      {popups === 'promo-schedule1' && (
        <div className="popup">
          <div className="popup-bg" onClick={() => dispatch(setPopup(null))} />
          <div className="popup-in popup-in-schedule">
            <div className="popup-close" onClick={() => dispatch(setPopup(null))}><Close /></div>
            <PopupShedule1 close={() => dispatch(setPopup(null))} />
          </div>
        </div>
      )}
      {popups === 'promo-schedule2' && (
        <div className="popup">
          <div className="popup-bg" onClick={() => dispatch(setPopup(null))} />
          <div className="popup-in popup-in-schedule">
            <div className="popup-close" onClick={() => dispatch(setPopup(null))}><Close /></div>
            <PopupShedule2 close={() => dispatch(setPopup(null))} />
          </div>
        </div>
      )}
      {popups === 'not-started' && (
        <div className="popup">
          <div className="popup-bg" onClick={() => dispatch(setPopup(null))} />
          <div className="popup-in">
            <div className="popup-close" onClick={() => dispatch(setPopup(null))}><Close /></div>
            <PopupNotStarted close={() => dispatch(setPopup(null))} />
          </div>
        </div>
      )}
      {popups === 'promo-double-msg' && (
        <div className="popup">
          <div className="popup-bg" onClick={() => dispatch(setPopup(null))} />
          <div className="popup-in">
            <div className="popup-close" onClick={() => dispatch(setPopup(null))}><Close /></div>
            <PopupDoubleMsg close={() => dispatch(setPopup(null))} />
          </div>
        </div>
      )}
    </>
  )
}

export default Popups;