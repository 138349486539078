import React, {useState} from 'react';

const Item = ({el}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="store-grid__item">
      {el.logo && <img src={el.logo} alt="" /> }
      {el.title && <div className="store-grid__title" dangerouslySetInnerHTML={{__html: el.title}} />}
      <div className="store-grid__floor" dangerouslySetInnerHTML={{__html: el.floor}} />
      <div>
        {el.text1 && <div className="store-grid__text" dangerouslySetInnerHTML={{__html: el.text1}} />}
        {el.text2 && open && <div className="store-grid__text" dangerouslySetInnerHTML={{__html: el.text2}} />}
        {el.text2 && <div className="store-grid__link" onClick={() => setOpen(!open)}>{open ? 'Скрыть' : 'Показать'}</div>}
      </div>
    </div>
  );
};

export default Item;