import React, {useState} from "react";
import AboutPromotions from "./AboutPromotions/AboutPromotions";
import ComingSoon from "./ComingSoon/ComingSoon";
import Eligibility from "./Eligibility/Eligibility";
import Navigation from "../../components/Navgation/Navigation";
import Partners from "./Partners/Partners";
import PointsSystem from "./PointsSystem/PointsSystem";
import PrizeFund from "./PrizeFund/PrizeFund";
import QuestionsSection from "./QuestionsSection/QuestionsSection";
import Winners from "./Winners/Winners";
import Footer from '../../components/footer/index'
import Ellipse from "../../components/ellipse";
import './main.css'

function Main() {
  return (
    <div className="main">
      <div className="main__bg">
        <div className="main-content__bg"><span/><span/><span/><span/></div>
        <div className="main-content__bg main-content__bg--2"><span/><span/><span/><span/><span/></div>
      </div>
      <div className="main__bg" style={{zIndex: 1}}>
        <Ellipse  type='pink' className="ellipse--1"/>
        <Ellipse  type='blue' className="ellipse--2"/>
        <div className="main-icon main-icon1"><img src="/img/lk/lk1.png?2" width={200} height={200} /></div> {/*blue*/}
        <div className="main-icon main-icon2"><img src="/img/lk/lk2.png?2" width={200} height={200} /></div> {/*pink*/}
        <div className="main-icon main-icon3"><img src="/img/lk/lk5.png?2" width={200} height={200} /></div> {/*yellow*/}
        <div className="main-icon main-icon4"><img src="/img/lk/lk5.png?2" width={200} height={200} /></div> {/*yellow*/}
        <div className="main-icon main-icon5"><img src="/img/lk/lk2.png?2" width={200} height={200} /></div> {/*pink*/}
        <div className="main-icon main-icon6"><img src="/img/lk/lk1.png?2" width={200} height={200} /></div> {/*blue*/}
      </div>
      <Navigation />
      <div className="main__content">
        <ComingSoon />
        <AboutPromotions />
      </div>
        <Eligibility />
        <PrizeFund />
        <PointsSystem/>
        <Winners/>
        <Partners/>
        <QuestionsSection/>
      <Footer hompage={true}/>


    </div>
  );
}

export default Main;
