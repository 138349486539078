import React from "react";
import { EligibilityIcon } from "../EligibilityIcon/EligibilityIcon";
import {useDispatch, useSelector} from "react-redux";
import { setPopup } from "../../../components/popups/store";
import "./eligibility.css";
import Slider from "react-slick";
import {userSelector} from "../../../redux/auth";
import Timer from "../../../components/Timer/Timer";

function Eligibility() {
  const user = useSelector(userSelector)
  const dispatch = useDispatch()
  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    slidesToShow: 10,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          //infinite: true,
          //dots: true
        }
      }
    ]
  };
  const handleClick = () => {
    window.dataLayer.push({
      'event': 'registration_click', 
      'eventCategory': 'click', 
      'eventAction': 'registration_entrance'  
    })
    dispatch(setPopup('lk-login'))
  }
  return (
    <>
      <div className="eligibility_Box" id='Eligibility'>
        <h3 className="eligibility_title">правила участия</h3>
        <Slider {...settings}>
          <div className="num1">
          <EligibilityIcon
              name="FiveBox"
              title="Зарегистрируйся<br/> в личном кабинете"
              text="Номера телефонов регистрации на сайте<br/> и в мобильном приложении должны совпадать!"
              button={1}
              flex="left"
          /></div>
          <div className="num2">
          <EligibilityIcon
              name="TwoBox"
              title="Скачай мобильное<br/> приложение Afimall"
              button={2}
          /></div>
          <div className="num3">
          <EligibilityIcon
              name="SixBox"
              title="Соверши покупку<br/> в Афимолле от 300 ₽"
              flex="left"
              button={3}
          /></div>
          <div className="num4">
          <EligibilityIcon
              name="ForBox"
              title="Отсканируй чек<br/> в мобильном приложении"
              text="Не позднее 12 часов с момента покупки"
              size="34%"
              number="4"
              button={4}
          /></div>
          <div className="num5">
          <EligibilityIcon
              name="Circle"
              title="Накопи 50 баллов и обменяй<br/> на купон «Розыгрыш<br/> в мобильном приложении»"
              flex="left"
              button={5}
          /></div>
        </Slider>
        <div className="eligibility_Footer_Btn_Wrapper">
          <a href="/docs/Afimall_rules.pdf?3" target="_blank" className="page-btn">
            <span>Полные правила розыгрыша</span>
          </a>
        </div>
      </div>
      <div className="eligibility_Footer_Box_Wrapper">
        <div className="eligibility_Footer_Box_Wrapper__bg eligibility_Footer_Box_Wrapper__bg--1" />
        <div className="eligibility_Footer_Box">
          {user
            ? <div>Успейте участвовать <br/> в розыгрыше призов</div>
            : <div>Успейте зарегистрироваться <br/> на розыгрыш призов</div>
          }
          {user
            ? <Timer />
            : <span className="page-btn" onClick={() => handleClick()}><span>Зарегистрироваться</span></span>
          }
        </div>
        <div className="eligibility_Footer_Box_Wrapper__bg eligibility_Footer_Box_Wrapper__bg--2" />
      </div>
    </>
  );
}

export default Eligibility;
