import React, {useState} from "react";
import Ten from "../../../assets/icon/ten.svg";
import Thousand from "../../../assets/icon/thousand.svg";
import "./prizeFund.css";
import Ellipse from "../../../components/ellipse";
import SliderBlock from './slider'

function PrizeFund() {
  const [tab, setTab] = useState(1)
  return (
    <>
        <div className="prize_Fund_Box" id="PrizeFund">
          <div className="prize_Fund_Box__bg">
            <Ellipse type='pink' className="prize_Fund_Box__bg__pink" />
            <Ellipse type='blue' className="prize_Fund_Box__bg__blue" />
            <div className="main-icon prize-icon2"><img src="/img/lk/lk2.png?2" width={200} height={200} /></div> {/*pink*/}
            <div className="main-icon prize-icon3"><img src="/img/lk/lk5.png?2" width={200} height={200} /></div> {/*yellow*/}
            <div className="main-icon prize-icon4"><img src="/img/lk/lk1.png?2" width={200} height={200} /></div> {/*blue*/}
          </div>
          <div className="prize_Fund_Box__title">Призовой фонд</div>
          <div className="prize_Fund_Box_text-top">
            Соверши покупку на общую сумму 5&nbsp;000&nbsp;₽ в течение одной недели,
            накопи 50 баллов и обменяй их на купон «Розыгрыш». <br /> Больше
            купонов — больше шанс выиграть!
          </div>
          <div className="prize-row prize-row--1">
            <img src={Ten} width={400} height={385} />
            <span>суперпризов <br/>каждую неделю</span>
          </div>
          <div className="prize-row prize-row--2">
            <span>более</span>
            <img src={Thousand} width={392} height={129} />
            <span>призов<br/>за весь период</span>
          </div>
          <div className="prize_Fund_Box_text-middle">Беспроигрышная акция</div>
        </div>
        <div className="slider-tabs">
          <div>
            <span data-active={tab===1} onClick={() => setTab(1)}>Суперпризы</span>
            <span data-active={tab===2} onClick={() => setTab(2)}>дополнительные призы</span>
          </div>
        </div>
        <SliderBlock tab={tab} />
        <div className="prize_Fund_Box_text-bottom">
          Выдача всех призов проводится на территории ТРЦ Афимол Сити на стойке
          выдачи призов (Центральный атриум).
        </div>
    </>
  );
}

export default PrizeFund;
