import {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import Countdown from 'react-countdown';
import SimpleBar from 'simplebar-react';
import { useDispatch } from 'react-redux';
import './style.css';
import Navigation from "../../components/Navgation/Navigation";
import Footer from '../../components/footer';
import { setPopup  } from '../../components/popups/store';
import {canRotateSelector, checkCanRotate, userSelector, countCouponsSelector, promoCode} from "../../redux/auth";
import { useHistory } from "react-router-dom";
import {Link} from 'react-router-dom'

const Lk = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const mobile = useSelector(state => state.mobile);
  const {mygifts, coupons, user} = useSelector(state => state.auth)
  const count = useSelector(countCouponsSelector)
  const canRotate = useSelector(canRotateSelector);

  const [promocode, setPromocode] = useState('');

  useEffect(() => {
    if (canRotate) history.push('/wheel')
  }, [canRotate])

  const handlePromoCode = () => {
    if (promocode) {
      const formData = new FormData()
      formData.append('code', promocode)
      dispatch(promoCode(formData, promocode))
      setPromocode('')
    }
  }

  const date1 = (new Date(2021, 8, 13, 21, 0)).getTime()
  const date2 = (new Date()).getTime();
  const declOfNum = (number, titles) => {
    const cases = [2, 0, 1, 1, 1, 2]
    number = parseInt(number)
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
  }
  const zeroPaddOfNum = (number) => {
    return (number.toString().length < 2 ? '0' : '') + number
  }
  const getCurrent = () => coupons.find(el => el.isCurrent)
  const current = getCurrent()
  const dates = [
    (new Date(2021, 8, 13, 20, 0, 0)).getTime(),
    (new Date(2021, 8, 20, 20, 0, 0)).getTime(),
    (new Date(2021, 8, 27, 20, 0, 0)).getTime(),
    (new Date(2021, 9, 4, 20, 0, 0)).getTime(),
    (new Date(2021, 9, 11, 20, 0, 0)).getTime(),
    (new Date(2021, 9, 18, 20, 0, 0)).getTime(),
    (new Date(2021, 9, 25, 20, 0, 0)).getTime(),
    (new Date(2021, 10, 1, 20, 0, 0)).getTime(),
    (new Date(2021, 10, 8, 20, 0, 0)).getTime(),
    (new Date(2021, 10, 15, 20, 0, 0)).getTime(),
  ]
  const time = (new Date()).getTime();
  const date = dates.filter(function(it, i) {return (it > time)})[0]

  return(
    <div className="lk">
      <Navigation logo_link={true} />
      <img src="/img/lk/lk1.png?2" className="lk_el1" />
      <img src="/img/lk/lk2.png?2" className="lk_el2" />
      <img src={mobile ? '/img/lk/lk3_mob.png?2' : '/img/lk/lk3.png?2'} className="lk_el3" />
      <img src={mobile ? '/img/lk/lk4_mob.png?2' : '/img/lk/lk4.png?2'} className="lk_el4" />
      <img src="/img/lk/lk5.png?2" className="lk_el5" />
      <img src="/img/lk/lk6.png?2" className="lk_el6" />
      <img src="/img/lk/lk7.png?2" className="lk_el7" />

      <div className="lk_el8" />
      <div className="lk_el9" />

      <div className="lk-in">
        <div className="lk-head">
          <div>
            <div className="lk-head-name">{user.fullname ? user.fullname : '-'}</div>
            <div className="lk-head-edit-lnk" onClick={() => dispatch(setPopup('lk-edit'))}>Редактировать</div>
          </div>
          <div>
            <div className="lk-head-label">Телефон</div>
            <div className="lk-head-val">{user.phone ? `+7 (${user.phone.slice(0, 3)}) ${user.phone.slice(3, 6)}-${user.phone.slice(6, 8)}-${user.phone.slice(8, 10)}` : '-'}</div>
          </div>
          {user.email && <div>
            <div className="lk-head-label">Электронная почта</div>
            <div className="lk-head-val">{user.email ? user.email : '-'}</div>
          </div>}
        </div>

        <div className="lk-center">
          <div>
            <div className="lk-counter">
              {!mobile && <div className="lk-counter-title">Cупер-розыгрыш стартует через</div>}
              <div className="lk-counter-in">
                <div>
                  {mobile && <div className="lk-counter-title">Cупер-розыгрыш стартует через</div>}
                  <Countdown
                    date={date}
                    renderer={({days, hours, minutes, seconds}) => {
                      return (
                        <div className="lk-countdown">
                          <div><div>{zeroPaddOfNum(days)}</div><span>{declOfNum(days, ['день','дня','дней'])}</span></div>
                          <div className="lk-countdown-sep">:</div>
                          <div><div>{zeroPaddOfNum(hours)}</div><span>{declOfNum(hours, ['час','часа','часов'])}</span></div>
                          <div className="lk-countdown-sep">:</div>
                          <div><div>{zeroPaddOfNum(minutes)}</div><span>{declOfNum(minutes, ['минута','минуты','минут'])}</span></div>
                          <div className="lk-countdown-sep">:</div>
                          <div><div>{zeroPaddOfNum(seconds)}</div><span>{declOfNum(seconds, ['секунда','секунды','секунд'])}</span></div>
                        </div>
                      );
                    }}
                  />
                  <a href="https://ru.tv/" target="_blank" className="lk-counter-lnk">На сайт розыгрыша</a>
                </div>
                <div>
                  <img src="/img/lk/tv.png?2" className="lk-center-tv" />
                </div>
              </div>
            </div>
            <img src={mobile ? '/img/lk/lk_bg_mob.png?2' : '/img/lk/lk_bg.png?2'} className="lk-center-bg" />
          </div>
          <div>
            <div className="lk-counter-in2">
              <div>
                <div className="lk-wheel-title">Колесо фортуны</div>
                {(date2 >= date1) && <div className="lk-button" onClick={() => dispatch(checkCanRotate())}>Участвовать</div>}
                {(date2 < date1) && <div className="lk-button" onClick={() => dispatch(setPopup('not-started'))}>Участвовать</div>}

              </div>
              <div>
                <img src="/img/lk/wheel.png?2" className="lk-center-wheel" />
              </div>
            </div>
            <img src={mobile ? '/img/lk/lk_bg_mob.png?2' : '/img/lk/lk_bg.png?2'} className="lk-center-bg" />
          </div>
        </div>

        <div className="lk-history" id="coupons">
          <div>
            <div className="lk-history-title">Мои купоны</div>
            {!mobile && (
              <>
                <div className="lk-history-count"><div>{current ? current.count : 0}</div>{current ? current.weekNumber : 1} неделя</div>
                <div className="lk-history-dsc">Купоны собранные на этой неделе</div>
              </>
            )}
            {mobile && (
              <div className="lk-history-count-mobile">
                <div>{current ? current.count : 0}</div>
                <div>
                  <div>{current ? current.weekNumber : 1} неделя</div>
                  Купоны собранные на этой неделе
                </div>
              </div>
            )}
            <div className="lk-history-buttons">
              <div className="input"><input placeholder="Ввести промокод" value={promocode} onChange={e => setPromocode(e.target.value)} /></div>
              <div className={!promocode ? 'lk-button disabled' : 'lk-button'} onClick={handlePromoCode}>добавить<div className="lk-button-alert">?<span>промокод добавляет<br/>+25 баллов</span></div></div>
            </div>
            <div className="lk-history-dsc">
              Введите промокод и&nbsp;вам начислятся 25&nbsp;бонусных баллов при&nbsp;следующем сканировании чека.<br/>Следующее сканирование чека возможно по&nbsp;истечении 15&#8209;ти минут после введения промокода.<br/>Зачисление баллов по&nbsp;промокоду производится 1&nbsp;раз за&nbsp;весь период акции.
            </div>
          </div>
          <div>
            <div className="lk-history-list-head">
              <div className="lk-history-list-title">История купонов</div>
              <span className="lk-history-list-lnk" onClick={() => dispatch(setPopup('promo-double-msg'))}>Как удвоить купоны?</span>
            </div>

            {coupons.length>0 && mobile &&
              <>
                {coupons.map(el =>
                  <div className="lk-history-item">
                    <div>{el.weekPeriod}</div>
                    <div>{el.weekNumber} Неделя</div>
                    <div>{el.hasPromoCode ? 'Применен промокод' : '-'}</div>
                    <div>{el.count} {declOfNum(el.count, ['купон','купона','купонов'])}</div>
                  </div>
                )}
              </>
            }

            {coupons.length>0 && !mobile && (
              <SimpleBar style={{ maxHeight: 222 }} forceVisible={mygifts.length>4}  className="lk-history-area">
                {coupons.map(el =>
                  <div className="lk-history-item">
                    <div>{el.weekPeriod}</div>
                    <div>{el.weekNumber} Неделя</div>
                    <div>{el.hasPromoCode ? 'Применен промокод' : '-'}</div>
                    <div>{el.count} {declOfNum(el.count, ['купон','купона','купонов'])}</div>
                  </div>
                )}
              </SimpleBar>
            )}
          </div>
        </div>

        {mygifts.length>0 && <div className="lk-prize-title" id="prize">Мои призы</div>}

        {mygifts.length>0 && mobile && (
          <>
            {mygifts.map(el =>
              <div className="lk-prize-list-item-mobile">
                <div className="lk-prize-list-item-mobile-title">{el.title}</div>
                <div className="lk-prize-list-item-mobile-content">
                  <div>
                    <div>Период</div>
                    <div>{el.weekPeriod}</div>
                  </div>
                  <div>
                    <div>Дата</div>
                    <div>{el.date}</div>
                  </div>
                  <div>
                    <div>Статус</div>
                    {el.isReceived ? <div>Приз получен</div> : <div><span onClick={() => dispatch(setPopup('lk-prize'))}>Заберите приз</span></div>}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {mygifts.length>0 && !mobile && (
          <SimpleBar style={{ maxHeight: 457 }} forceVisible={mygifts.length>4}  className="lk-history-area-prize">
            <div className="lk-prize-list-item lk-prize-list-item-head">
              <div>Период</div>
              <div>Приз</div>
              <div>Дата выигрыша</div>
              <div>Приз</div>
            </div>
            {mygifts.map(el =>
              <div className="lk-prize-list-item">
                <div>{el.weekPeriod}<span>{el.weekNumber} неделя</span></div>
                <div>{el.title}<span>Колесо фортуны</span></div>
                <div>{el.date}</div>
                {el.isReceived
                  ? <div>Приз получен</div>
                  : <div>Заберите приз<span className="lk-prize-list-item-lnk" onClick={() => dispatch(setPopup('lk-prize'))}>Где получить приз?</span></div>
                }
              </div>
            )}
          </SimpleBar>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default Lk;
