import {useSelector} from "react-redux";
import {codeSelector} from "../../redux/auth";

const PopupPromocode = ({close}) => {
  const code = useSelector(codeSelector)
  const apply = () => {
    close();
  }

  return(
    <>
      <div className="popup-title">Спасибо Ваш промокод получен.</div>
      <div className="popup-dsc">После проверки Вам начислят баллы.</div>
      <div className="lk-button" onClick={apply}><span>понятно</span></div>
    </>
  )
}

export default PopupPromocode;