import React from "react";
import { ReactComponent as Play } from "../../../assets/icon/play.svg";
import { ReactComponent as Apple } from "../../../assets/icon/apple.svg";
import {useDispatch, useSelector} from "react-redux";
import { setPopup } from "../../../components/popups/store";
import "./eligibilityIcon.css";
import {userSelector} from "../../../redux/auth";
import { ReactComponent as Icon1 } from "./icons/FiveBox.svg";
import { ReactComponent as Icon2 } from "./icons/TwoBox.svg";
import { ReactComponent as Icon3 } from "./icons/SixBox.svg";
import { ReactComponent as Icon4 } from "./icons/ForBox.svg";
import { ReactComponent as Icon5 } from "./icons/Circle.svg";

export const EligibilityIcon = ({
  title,
  text,
  button,
  flex
}) => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const handleClick = () => {
    window.dataLayer.push({
      'event': 'registration_click', 
      'eventCategory': 'click', 
      'eventAction': 'registration_entrance'  
    })
    dispatch(setPopup('lk-login'))
  }
  return (
    <div className={`${flex==='left'?'flex_Left':'flex_Right'}`}>
      <div className="icon_Wrapper">
        <div className="icon_Box">
          {button === 1 && <Icon1 />}
          {button === 2 && <Icon2 />}
          {button === 3 && <Icon3 />}
          {button === 4 && <Icon4 />}
          {button === 5 && <Icon5 />}
        </div>

        <div className="icon_Body">
          <p className="eligibility_Card_Context" dangerouslySetInnerHTML={{__html: title}} />

          {text && <p className="uniq_Text" dangerouslySetInnerHTML={{__html: text}} />}

          {button === 1 && !user &&
            <div>
              <div className="page-btn" onClick={() => handleClick()}><span>Зарегистрироваться</span></div>
            </div>
          }
          {button === 2 &&
            <div className='btn_Wrapper'>
              <a href="https://itunes.apple.com/ru/app/%D0%B0%D1%84%D0%B8%D0%BC%D0%BE%D0%BB%D0%BB-%D1%81%D0%B8%D1%82%D0%B8/id880093759?mt=8" target="_blank" rel="noopener noreferrer" className="eligibility_Btn_Icon">
                <Apple />
                <span>
                  Скачать в <br />
                  App Store
                </span>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.afimall.apps.android" rel="noopener noreferrer" target="_blank" className="eligibility_Btn_Icon">
                <Play />
                <span>
                  Скачать в <br />
                  Google Play
                </span>
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  );
};
