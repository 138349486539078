import React, {useState} from 'react';
import {useSelector} from "react-redux";
import Slider from 'react-slick'
import {ReactComponent as Prev} from "./icons/prev.svg"
import {ReactComponent as Next} from "./icons/next.svg"
import Ipad from "./icons/ipad.svg";
import Logo from "./icons/logo.png?2";
import Phone from "./icons/phone.svg";
import Person from "./icons/person.svg";
import PersonLogo from "./icons/personLogo.png?2";
import Plates from "./icons/plates.svg";
import PlatesLogo from "./icons/platesLogo.png?2";
import Bag from "./icons/bag.svg";
import BagLogo from "./icons/bagLogo.png?2";
import BigBag from "./icons/bigBag.svg";
import RivGosh from "./icons/rivGosh.png?2";
import Cosmic from "./icons/cosmic.png?2";
import Olga from "./icons/olga.png?2";
import Kari from "./icons/kari.png?2";
import Ikea from "./icons/ikea.png?2";
import Karat from "./icons/karat.png?2";
import Letoile from "./icons/letoile.png?2";
import BodyShop from "./icons/bodyShop.png?2";
import Kanzler from "./icons/kanzler.png?2";
import Insam from "./icons/insam.png?2";
import Uniqlo from "./icons/uniqlo.png?2";
import Erborian from "./icons/erborian.png?2";
import Togas from "./icons/togas.png?2";
import GoldenApple from "./icons/goldenApple.png?2";
import Miuz from "./icons/miuz.png?2";
import Outlet from "./icons/outlet.png?2";
import Epl from "./icons/epl.png?2";
import Victoria from "./icons/victoria.png?2";

const SliderBlock = ({tab}) => {
  const mobile = useSelector(state => state.mobile)
  const [coupon, setCoupon] = useState(1);
  const move = tab !== 2;

  const settings = {
    className: "slider variable-width",
    dots: false,
    arrows: false,
    infinite: move,
    centerMode: move,
    slidesToShow: move ? 1 : 5,
    slidesToScroll: 1,
    variableWidth: move,
    speed: move && !mobile ? 5000 : 500,
    autoplaySpeed: 0,
    autoplay: move && !mobile,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          infinite: true,
          adaptiveHeight: true,
        }
      }
    ]
  };
  if (tab === 2)
    return (
      <div>
        <div className="coupon-tabs">
          <div>
            <span className={'coupon-arrow coupon-prev' + (coupon===1 ? ' disabled' : '')}><Prev onClick={() => {if (coupon!==1) setCoupon(coupon-1)}} /></span>
            <span className={'coupon-item' + (coupon===1 ? ' active' : '')} onClick={() => setCoupon(1)}>
              <big>1 купон</big>
              <small>Призы до 3 000р</small>
            </span>
            <span className={'coupon-item' + (coupon===2 ? ' active' : '')} onClick={() => setCoupon(2)}>
              <big>2-3 купона</big>
              <small>Призы до 5 000р</small>
            </span>
            <span className={'coupon-item' + (coupon===3 ? ' active' : '')} onClick={() => setCoupon(3)}>
              <big>4-7 купонов</big>
              <small>Призы до 10 000р</small>
            </span>
            <span className={'coupon-item' + (coupon===4 ? ' active' : '')} onClick={() => setCoupon(4)}>
              <big>8+ купонов</big>
              <small>Призы до 20 000р</small>
            </span>
            <span className={'coupon-arrow coupon-next' + (coupon===4 ? ' disabled' : '')}><Next onClick={() => {if (coupon!==4) setCoupon(coupon+1)}} /></span>
          </div>
        </div>
        {coupon===1 && <Slider {...settings}>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Сертификат от косметического магазина РИВ ГОШ</div>
              <img src={RivGosh} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Сертификат от парка развлечений Космик</div>
              <img src={Cosmic} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Сертификат от бренда авторских платьев Olga Grinyuk</div>
              <img src={Olga} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Сертификаты от магазина обуви Kari</div>
              <img src={Kari} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Подарочная карта от Ikea</div>
              <img src={Ikea} alt="" />
            </div>
          </div>
        </Slider>}
        {coupon===2 && <Slider {...settings}>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Серебряная подвеска от ювелирного магазина "Карат"</div>
              <img src={Karat} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Сертификат от магазина косметики L’Etoile</div>
              <img src={Letoile} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Сертификат от ресторана Kabuki</div>
              <img src={PlatesLogo} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Набор от The Body Shop</div>
              <img src={BodyShop} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Сертификат от магазина женской одежды Vittoria Vicci </div>
              <img src={Victoria} alt="" />
            </div>
          </div>
        </Slider>}
        {coupon===3 && <Slider {...settings}>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Сертификат от магазина мужской одежды KANZLER</div>
              <img src={Kanzler} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Сертификат от ресторана Инсам</div>
              <img src={Insam} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Кашемировый шарф от Uniqlo</div>
              <img src={Uniqlo} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Косметический набор от Erborian</div>
              <img src={Erborian} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Сертификат от бутика домашнего текстиля Togas</div>
              <img src={Togas} alt="" />
            </div>
          </div>
        </Slider>}
        {coupon===4 && <Slider {...settings}>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Косметические наборы от магазина Золотое Яблоко</div>
              <img src={GoldenApple} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Ювелирное украшение от Московского Ювелирного Завода</div>
              <img src={Miuz} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Сертификат от ЦУМ Outlet</div>
              <img src={Outlet} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Ювелирное украшение от магазина ЭПЛ Якутские Бриллианты</div>
              <img src={Epl} alt="" />
            </div>
          </div>
          <div className="CaruselCard_icon_Wrapper">
            <div className="CaruselCard_icon_Box">
              <div className="CaruselCard_icon_Text">Чемодан бренда American Tourister</div>
              <img src={BagLogo} alt="" />
            </div>
          </div>
        </Slider>}
        <div className="prize_Fund_Box_text-other">... и другие</div>
      </div>
    );
  return (
    <div>
      <Slider {...settings}>
        <div className="CaruselCard_icon_Wrapper">
          <div className="CaruselCard_icon_Box">
            <div className="CaruselCard_icon_Text">Планшет iPad Air 10.9</div>
            <img src={Ipad} alt="" />
            <img src={Logo} alt="" />
          </div>
        </div>
        <div className="CaruselCard_icon_Wrapper">
          <div className="CaruselCard_icon_Box">
            <div className="CaruselCard_icon_Text">Cмартфон iPhone 11</div>
            <img src={Phone} alt="" />
            <img src={Logo} alt="" />
          </div>
        </div>
        <div className="CaruselCard_icon_Wrapper">
          <div className="CaruselCard_icon_Box">
            <div className="CaruselCard_icon_Text">Абонемент на 1 год в спортивный клуб 5 Элемент</div>
            <img src={Person} alt="" />
            <img src={PersonLogo} alt="" />
          </div>
        </div>
        <div className="CaruselCard_icon_Wrapper">
          <div className="CaruselCard_icon_Box">
            <div className="CaruselCard_icon_Text">Сертификат в ресторан Kabuki (30&nbsp;000&nbsp;р)</div>
            <img src={Plates} alt="" />
            <img src={PlatesLogo} alt="" />
          </div>
        </div>
        <div className="CaruselCard_icon_Wrapper">
          <div className="CaruselCard_icon_Box">
            <div className="CaruselCard_icon_Text">Стильный рюкзак TUMI</div>
            <img src={Bag} alt="" />
            <img src={BagLogo} alt="" />
          </div>
        </div>
        <div className="CaruselCard_icon_Wrapper">
          <div className="CaruselCard_icon_Box">
            <div className="CaruselCard_icon_Text">Чемодан Samsonite для ручной клади</div>
            <img src={BigBag} alt="" />
            <img src={BagLogo} alt="" />
          </div>
        </div>
        <div className="CaruselCard_icon_Wrapper">
          <div className="CaruselCard_icon_Box">
            <div className="CaruselCard_icon_Text">Cмартфон iPhone 11</div>
            <img src={Phone} alt="" />
            <img src={Logo} alt="" />
          </div>
        </div>
        <div className="CaruselCard_icon_Wrapper">
          <div className="CaruselCard_icon_Box">
            <div className="CaruselCard_icon_Text">Абонемент на 1 год в спортивный клуб 5 Элемент</div>
            <img src={Person} alt="" />
            <img src={PersonLogo} alt="" />
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default SliderBlock;
