import React from "react";
import logo1 from '../../../assets/images/logo1.png?2';
import logo2 from '../../../assets/images/logo2.png?2';
import logo3 from '../../../assets/images/logo3.png?2';
import logo4 from '../../../assets/images/logo4.png?2';
import logo1_mob from '../../../assets/images/logo1.png?2';
import logo2_mob from '../../../assets/images/logo2.png?2';
import logo3_mob from '../../../assets/images/logo3.png?2';
import logo4_mob from '../../../assets/images/logo4.png?2';
import Slider from "react-slick";
import {useSelector} from "react-redux";


import "./partners.css";

export default function Partners() {
    const mobile = useSelector(state => state.mobile)
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        //centerMode: true,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className='partners_Box'>
            <div className="main-icon partners-icon2"><img width={200} height={200} src="/img/lk/lk2.png?2" /></div> {/*pink*/}
            <div className="main-icon partners-icon3"><img width={200} height={200} src="/img/lk/lk5.png?2" /></div> {/*yellow*/}
            <div className="main-icon partners-icon4"><img width={200} height={200} src="/img/lk/lk1.png?2" /></div> {/*blue*/}
            <div className='partners_Title'>Партнеры</div>

            {!mobile && <div className='partners_Logo_Box_Wrapper'>
                <div className='partners_Logo_Box'><img width={200} height={200} src={logo1} alt="" /></div>
                <div className='partners_Logo_Box'><img width={200} height={200} src={logo3} alt="" /></div>
                <div className='partners_Logo_Box'><img width={200} height={200} src={logo2} alt="" /></div>
                <div className='partners_Logo_Box'><img width={200} height={200} src={logo4} alt="" /></div>
            </div>}
            {mobile && <div className='partners_Logo_Box_Wrapper'>
                <Slider {...settings}>
                    <div className="partners_Logo_Box__out"><div className='partners_Logo_Box'><img width={200} height={200} src={logo1_mob} alt="" /></div></div>
                    <div className="partners_Logo_Box__out"><div className='partners_Logo_Box'><img width={200} height={200} src={logo3_mob} alt="" /></div></div>
                    <div className="partners_Logo_Box__out"><div className='partners_Logo_Box'><img width={200} height={200} src={logo2_mob} alt="" /></div></div>
                    <div className="partners_Logo_Box__out"><div className='partners_Logo_Box'><img width={200} height={200} src={logo4_mob} alt="" /></div></div>
                </Slider>
            </div>}
        </div>
    );
}
