import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setPopup} from "../../../components/popups/store";
import tv from '../../../assets/images/lk-TV.png?2'
import wheel from '../../../assets/images/lk-fortune-wheel.png?2'
import "./aboutPromotions.css";
import {Link} from "react-router-dom";
import {userSelector} from "../../../redux/auth";

export default function AboutPromotions() {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const date1 = (new Date(2021, 8, 13, 21, 0)).getTime()
  const date2 = (new Date()).getTime();
  return (
    <div className="promo_Action_Box" id='AboutPromotions'>
      <div className="promo_Action_Box__title">Об акции</div>
      <p className="unit">
        ТРЦ «Афимолл Сити» в честь юбилея запускает Шоппинг марафон<br />
        Розыгрыш призов каждую неделю с 6 сентября по 14 ноября 2021
      </p>
      <div className="promo_Card_Box">
        <div className="propmo_Card_Box">
          <p className="uniq_Title_Color_Style">
            Розыгрыш<br/> суперпризов
          </p>
          <p className="title_2">
            Проведение розыгрыша «Суперпризов» проходит еженедельно 
            <br/> в прямом эфире телеканала RU.TV
          </p>
          <div className="propmo_Card_Img_Box">
            <img src={tv} alt="" height={342} width={412} />
          </div>

          <div className="propmo_btn">
            <a href="https://ru.tv/" target="_blank" className="page-btn"><span>на сайт розыгрыша</span></a>
            <span className="btn_Text btn_Link" onClick={() => dispatch(setPopup('promo-schedule1'))}>расписание розыгрышей</span>
          </div>
        </div>
        <div className="propmo_Card_Box">
          <p className="uniq-Title">
            Розыгрыш «Колесо фортуны» (дополнительные призы)
          </p>
          <p className="title_2">
            Проводится на данном сайте, открывается через 30 минут после
            <br/> розыгрыша Суперпризов и активен в течение недели
            <br/> (см. расписание ниже)
          </p>
          <div className="propmo_Card_Img_Box">
            <img src={wheel} alt="" height={362} width={327}  />
          </div>

          <div className="propmo_btn">
            {(user && date2 >= date1) && <Link to="/wheel" className="page-btn"><span>принять участие</span></Link>}
            {(user && date2 < date1) && <span className="page-btn" onClick={() => dispatch(setPopup('not-started'))}><span>принять участие</span></span>}
            <span className="btn_Text btn_Link" onClick={() => dispatch(setPopup('promo-schedule2'))}>расписание розыгрышей</span>
          </div>
        </div>
      </div>
    </div>
  );
}
