import { useEffect, useState, useRef } from 'react'

export const usePrevious = (value) => {
  const ref = useRef({})
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useImagePreload = (images) => {
  const [count, setCount] = useState(0)
  const [inProcess, setInProcess] = useState(true)
  const prevState = usePrevious({ count })

  useEffect(() => {
    images.map((picture) => {
      const img = new Image()
      img.src = picture
      img.onload = () => setCount(val => val + 1)
      img.onerror = () => {
        setCount(val => val + 1)
        throw picture + ' not found'
      }
    })
  }, [])

  useEffect(() => {
    if (count === images.length && count !== prevState.count) {
      setInProcess(false)
    }
  }, [count])

  return [inProcess]
}





