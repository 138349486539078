import axios from 'axios'
import {setPopup} from "../components/popups/store";
// import { history } from '../index';
import {createSelector} from "reselect";

const domain = window.location.hostname === 'localhost' ? 'https://afi10.multicontent.ru' : ''
/**
 * Constants
 * */
export const moduleName = 'auth'
export const SIGN_IN_START = `${moduleName}/SIGN_IN_START`
export const SIGN_IN_SUCCESS = `${moduleName}/SIGN_IN_SUCCESS`

export const SIGN_OUT = `${moduleName}/SIGN_OUT`
export const SET_PHONE = `${moduleName}/SET_PHONE`
export const SET_COUNTER = `${moduleName}/SET_COUNTER`
export const SET_SMSSEND = `${moduleName}/SET_SMSSEND`
export const SET_USER = `${moduleName}/SET_USER`
export const CHECKED_AUTHORIZED = `${moduleName}/CHECKED_AUTHORIZED`
export const SET_ERROR_TEXT = `${moduleName}/SET_ERROR_TEXT`
export const CAN_ROTATE = `${moduleName}/CAN_ROTATE`
export const SET_MYGIFTS = `${moduleName}/SET_MYGIFTS`
export const SET_COUPONS = `${moduleName}/SET_COUPONS`
export const GET_WINNERS = `${moduleName}/GET_WINNERS`
export const SET_CODE = `${moduleName}/SET_CODE`
export const SET_ROTATE_GIFT = `${moduleName}/SET_ROTATE_GIFT`


/**
 * Reducer
 * */
const defaultState = {
  checkedAuthorized: false,
  loading: false,
  user: null,
  counter: 300,
  phone: null,
  smsSend: false,
  errorText: null,
  canRotate: false,

  mygifts: [],
  coupons: [],
  winners: [],
  code: null,
  gift: null,
  isDoubling: false,
  wheelId: null

}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case SIGN_IN_START:
      return { ...state, loading: true }

    case SIGN_IN_SUCCESS:
      return { ...state, loading: false}

    case SIGN_OUT:
      return {...defaultState, checkedAuthorized: true}

    case SET_PHONE:
      return {...state, phone: payload.phone}

    case SET_COUNTER:
      return {...state, counter: payload.counter}

    case SET_SMSSEND:
      return {...state, smsSend: payload.value}

    case SET_USER:
      return {...state, user: payload.user}

    case CHECKED_AUTHORIZED:
      return {...state, checkedAuthorized: true}

    case SET_ERROR_TEXT:
      return {...state, errorText: payload.error}

    case CAN_ROTATE:
      return {...state, canRotate: payload.canRotate, wheelId: payload.wheelId}

    case SET_MYGIFTS:
      return {...state, mygifts: payload.mygifts}

    case SET_COUPONS:
      return {...state, coupons: payload.coupons}

    case GET_WINNERS:
      return {...state, winners: payload.winners}

    case SET_CODE:
      return {...state, code: payload.code}

    case SET_ROTATE_GIFT:
      return {...state, gift: payload.gift, isDoubling: payload.isDoubling}

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const loadingSelector = (state) => state[moduleName].loading
export const userSelector = (state) => state[moduleName].user
export const errorSelector = (state) => state[moduleName].error
export const checkedAuthorizedSelector = (state) => state[moduleName].checkedAuthorized
export const errorTextSelector = (state) => state[moduleName].errorText
export const canRotateSelector = (state) => state[moduleName].canRotate
export const couponsSelector = (state) => state[moduleName].coupons
export const winnersSelector = (state) => state[moduleName].winners
export const codeSelector = (state) => state[moduleName].code
export const giftSelector = (state) => state[moduleName].gift
export const wheelIdSelector = (state) => state[moduleName].wheelId
export const isDoublingSelector = (state) => state[moduleName].isDoubling

export const countCouponsSelector = createSelector(
  couponsSelector,
  (coupons) => {
    return coupons.reduce((acc, el) => acc+el.count, 0)
  }
)
/**
 * Action Creators
 * */

export const isAuthorized = (params) => async (dispatch) => {
  if (domain) {
    await dispatch({type: CHECKED_AUTHORIZED})
    return dispatch(getProfile())
    // return dispatch({type: CHECKED_AUTHORIZED})
  }

  try {
    const { data } = await axios.get(domain + '/api/authorization/isAuthorized/')
    await dispatch({type: CHECKED_AUTHORIZED})
    if (data.isAuthorized) dispatch(getProfile())
  }
  catch (error) {dispatch(signOut())}
}

export const getProfile= (openEditForm) => async (dispatch) => {
  if (domain) {
    const data = {
      profile: {
        "id": 1,
        "phone": "9261231212",
        "fullname": "",
        "email": "my@email.ru"
      }
    }
    await dispatch(setUser(data.profile))
    if (openEditForm && (!data.profile.email || !data.profile.fullname)) {
      await dispatch(setPopup('lk-edit'))
    }
    await dispatch(getMyGifts())
    return dispatch(getCoupons())
  }

  try {
    const { data } = await axios.get(domain + '/api/user/profile/')
    if (data.profile) {
      await dispatch(setUser(data.profile))
      if (openEditForm && (!data.profile.email || !data.profile.fullname)) {
        await dispatch(setPopup('lk-edit'))
      }
      await dispatch(getMyGifts())
      await dispatch(getCoupons())
    }
    else dispatch(signOut())
  }
  catch (error) {dispatch(setPopup('lk-error'))}

}

export const profileUpdate= (params) => async (dispatch) => {
  if (domain) {
    await dispatch(setPopup('lk-signup-success'))
    return dispatch(getProfile())
  }
  try {
    const { data } = await axios.post(domain + '/api/user/profileUpdate/', params)
    if (data.success) {
      await dispatch(setPopup('lk-signup-success'))
      dispatch(getProfile())
    }
    else dispatch(setPopup('lk-error'))
  }
  catch (error) {
    if (error.response && error.response.data && error.response.data.errorMessage) {
      await dispatch(setErrorText(error.response.data.errorMessage))
    }
    return dispatch(setPopup('lk-error'))
  }

}


export const sendSms = (params, phone) => async (dispatch) => {
  dispatch({ type: SIGN_IN_START })
  if (domain) {
    const mes = "Следующее смс можно будет отправить через 86 сек."
    const r = /\d+/;
    const num = mes.match(r)
    if (num) {
      await dispatch(setCounter(+num))
      await dispatch(setSmsSend(true))
      return dispatch(setPopup('lk-sms'))
    }
    await dispatch(setErrorText(mes))
    return dispatch(setPopup('lk-error'))

    // await dispatch(setUserPhone(phone))
    // await dispatch(setSmsSend(true))
    // return dispatch(setPopup('lk-sms'))
  }

  try {
    const { data } = await axios.post(domain + '/api/authorization/token/', params)
    if (data.success)  {
      await dispatch(setUserPhone(phone))
      await dispatch(setSmsSend(true))
      dispatch(setPopup('lk-sms'))
    }
    else dispatch(setPopup('lk-error'))
  }
  catch (error) {
    if (error.response && error.response.data && error.response.data.errorMessage) {
      const r = /\d+/;
      const num = error.response.data.errorMessage.match(r)
      if (num) {
        await dispatch(setCounter(+num))
        await dispatch(setUserPhone(phone))
        await dispatch(setSmsSend(true))
        return dispatch(setPopup('lk-sms'))
      }
      await dispatch(setErrorText(error.response.data.errorMessage))
    }
    return dispatch(setPopup('lk-error'))
  }
}

export const signIn = (params) => async (dispatch) => {
  dispatch({ type: SIGN_IN_START })

  if (domain) {
    dispatch(setPopup(null))
    return dispatch(getProfile(true))
  }

  try {
    const { data } = await axios.post(domain + '/api/authorization/token/', params )
    if (data.success)  {
      dispatch(setPopup(null))
      dispatch(getProfile(true))
    }
    else dispatch(setPopup('lk-error'))
  }
  catch (error) {
    if (error.response && error.response.data && error.response.data.errorMessage) {
      await dispatch(setErrorText(error.response.data.errorMessage))
      dispatch(setPopup('lk-error'))
    }
  }
}

export const signOut = () => async (dispatch) => {
  try {
    const { data } = await axios.get(domain + '/api/authorization/logout/' )
    if (data.success)  dispatch({ type: SIGN_OUT })
  }
  catch (error) {}
}

export const setUserPhone = (phone) => ({type: SET_PHONE, payload: {phone}})
export const setUser = (user) => ({type: SET_USER, payload: {user}})
export const setCounter = (counter) => ({type: SET_COUNTER, payload: {counter}})
export const setSmsSend = (value) => ({type: SET_SMSSEND, payload: {value}})
export const setErrorText = (error) => ({type: SET_ERROR_TEXT, payload: {error}})
export const setCanRotate = (canRotate, wheelId) => ({ type: CAN_ROTATE, payload: {canRotate, wheelId} })
export const setCode = (code) => ({ type: SET_CODE, payload: {code} })
export const checkCanRotate = () => async (dispatch) => {
  if (domain) {
    const data = {
      "canRotate": true,
      "disallowReason": "Недостаточно купонов",
      "wheelId": 1
    }
    return dispatch(setCanRotate(data.canRotate, data.wheelId))
  }
  try {
    const { data } = await axios.get(domain + '/api/fortune/canRotate/' )
    dispatch(setCanRotate(data.canRotate, data.wheelId))
    if (!data.canRotate) {
      await dispatch(setErrorText(data.disallowReason))
      dispatch(setPopup('lk-error'))
    }
  }
  catch (error) {dispatch(setPopup('lk-error'))}
}

export const tryRotate = (params) => async (dispatch) => {
  if (domain) {
    const data = {
      "success": true,
      "gift": {
        "id": 1,
        "title": "фирменный брелок  от авиатренажера Dream Aero ",
        "text": "Mobile phone",
        "image": "/img/wheel/ipad.png?2",
        "place_id": 1
      },
      isDoubling: true,
      "activationCode": "123456AAA"
    }
    if (data.success) {
      if (data.isDoubling) await dispatch(getCoupons())
      return dispatch({type: SET_ROTATE_GIFT, payload: {gift:data.gift, isDoubling: data.isDoubling}})
    }
  }
  try {
    const { data } = await axios.post(domain + '/api/fortune/rotate/', params)
    if (data.success) {
      console.log(data)
      if (data.isDoubling) await dispatch(getCoupons())
      return dispatch({type: SET_ROTATE_GIFT, payload: {gift:data.gift, isDoubling: data.isDoubling}})
    }
  }
  catch (error) {dispatch(setPopup('lk-error'))}
}

export const getMyGifts = () => async (dispatch) => {
  if (domain) {
    const data = [
      {
        "weekNumber": 1,
        "weekPeriod": "6 - 12 сентября",
        "title": "Автомобиль",
        "type": "super",
        "date": "3 ноября",
        "isReceived": true
      }
    ]
    return dispatch({type: SET_MYGIFTS, payload: {mygifts:data}})
  }

  try {
    const { data } = await axios.get(domain + '/api/gifts/my/' )
    if (data.length) dispatch({type: SET_MYGIFTS, payload: {mygifts:data}})
  }
  catch (error) {
    await dispatch(signOut())
    dispatch(setPopup('lk-login'))
  }
}

export const getCoupons = () => async (dispatch) => {
  if (domain) {
    const data = [
      {
        "weekNumber": 1,
        "weekPeriod": "6 - 12 сентября",
        "count": 6,
        "hasPromoCode": true,
        isCurrent: true
      }
    ]
    return dispatch({type: SET_COUPONS, payload: {coupons:data}})
  }
  try {
    const { data } = await axios.get(domain + '/api/coupons/my/' )
    if (data.length) dispatch({type: SET_COUPONS, payload: {coupons:data}})
  }
  catch (error) {
    await dispatch(signOut())
    dispatch(setPopup('lk-login'))
  }
}

export const promoCode = (params, code) => async (dispatch) => {
  if (domain) {
    await dispatch(setCode(code))
    await dispatch(setPopup('lk-promocode'))
    return dispatch(getCoupons())
  }
  try {
    const { data } = await axios.post(domain + '/api/coupons/promoCode/', params )
    if (data.success) {
      await dispatch(setCode(code))
      await dispatch(setPopup('lk-promocode'))
      dispatch(getCoupons())
    }
    else dispatch(setPopup('lk-error'))
  }
  catch (error) {dispatch(setPopup('lk-error'))}
}



export const getWinners = () => async (dispatch) => {
  try {
    const { data } = await axios.get(domain + '/api/gifts/super/' )
    if (data.length) dispatch({type: GET_WINNERS, payload: {winners:data}})
  }
  catch (error) {}
}

