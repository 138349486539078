import React, {useEffect, useState} from "react";
import iphone from "../../../assets/images/iphone.png?2";
import {ReactComponent as Icon} from "./icons/1.svg"
import {ReactComponent as Prev} from "./icons/prev.svg"
import {ReactComponent as Next} from "./icons/next.svg"
import Slider from 'react-slick'
import "./winners.css";
import {useDispatch, useSelector} from "react-redux";
import {getWinners, promoCode, winnersSelector} from "../../../redux/auth";
import {getGifts} from "./utils";

export default function Winners() {
  const winners = useSelector(winnersSelector)
  const [active, setActive] = useState(null)
  const [tab, setTab] = useState(null)
  const mobile = useSelector(state => state.mobile)

  const dispatch = useDispatch()
  useEffect(() => {
    if (!winners.length) dispatch(getWinners())
  }, [])

  useEffect(() => {
    if (winners.length) {
      setActive(winners[0])
      const winnersPassed = winners.filter(el => el.gifts.length > 0)
      const winnersWeek = Math.max(winners[0].number, winnersPassed[winnersPassed.length - 1].number)
      setTab(winnersWeek)
    }
  }, [winners])

  const showHidden = () => {
    document.querySelectorAll('.winners__list__item').forEach(function (item)
    {
      item.style.display = 'block';
    });
    document.querySelector('.all-wins').style.display = 'none';
  }

  if (!winners.length || !active) return null
  const gifts = getGifts(winners)

  if (gifts.length === 0) return null
  const giftsTab = gifts.filter(el => el.number === tab)

  const settings = {
    arrows: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <span><Prev /></span>,
    nextArrow: <span><Next /></span>,
    adaptiveHeight: true,
    initialSlide: mobile ? tab - 1 : 0,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          afterChange: current => setTab(current + 1)
        }
      }
    ]
  };

  return (
    <div className="winners" id="Winners">
      <div className="winners__bg" />
      <div className="main-icon winners-icon2"><img src="/img/lk/lk2.png?2" alt="" /></div> {/*pink*/}
      <div className="main-icon winners-icon4"><img src="/img/lk/lk1.png?2" alt="" /></div> {/*blue*/}

      <div className="winners__content">
        <div className="winners__title">Победители Супер-розыгрыша</div>
        <div className="winners__weeks">
          <Slider {...settings}>
            {winners.map(el => <div key={el.number}>
              <div className="winners__weeks__item" data-active={el.number === tab}  data-disabled={(el.isFuture || el.isCurrent) && el.number !== 1} onClick={() => {if ((!el.isFuture && !el.isCurrent) || el.number === 1) setTab(el.number)}}>
                <div className="winners__weeks__item__icon"><Icon /></div>
                <div>
                  <div className="winners__weeks__item__title">{el.number} неделя</div>
                  <span>{el.period}</span>
                </div>
              </div>
            </div>)}
          </Slider>
        </div>

        {!mobile &&  <div className="winners__list">
          <div className="winners__list__item winners__list__item--header">
            <div>№</div>
            <div style={{ paddingLeft: "10px" }}>Приз</div>
            <div>Победитель</div>
            <div>Телефон</div>
            <div>Дата выигрыша</div>
          </div>
          {giftsTab.map((el, i) => <div className="winners__list__item">
            <div>{i+1}</div>
            <div className="winner__list__img">
              {el.image && <span><img src={el.image} alt="iphone" /></span>}
              <span>{el.title}</span>
            </div>
            <div>{el.winnerFullName}</div>
            <div>{el.winnerPhone}</div>
            <div>{el.date}</div>
          </div>)}
        </div>}
        {mobile &&  <div className="winners__list">
          {giftsTab.map((el, index) =>
            <div className="winners__list__item" Style={index > 4 ? 'display:none' : ''}>
              <div className="winners__list__item__title">
                {el.title}
              </div>
              <div className="winners__list__item__in">
                <div className="winners__list__item__block">
                  <div className="w_title">Победитель</div>
                  <div className="w_value">{el.winnerFullName}</div>
                </div>
                <div className="winners__list__item__block">
                  <div className="w_title">Телефон</div>
                  <div className="w_value">{el.winnerPhone}</div>
                </div>
                <div className="winners__list__item__block">
                  <div className="w_title">Дата</div>
                  <div className="w_value">{el.date}</div>
                </div>
              </div>
            </div>)}
        </div>}
        {mobile && giftsTab.length > 5 && <div className="page-btn trans all-wins" onClick={showHidden}><span>показать все</span></div> }

        <div className="winners__btn">
          <a href="https://www.youtube.com/channel/UCM2tYLK98SqA5tt6yGyNdPw" target="_blank" rel="noreferrer noopener" className="page-btn">
            <span>эфиры розыгрышей</span>
          </a>
        </div>
      </div>
    </div>
  );
}
